import React, { Component } from "react";
import { Layout, Menu, Button } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import '../../Styles/Global/sider.css';
import { useNavigate } from "react-router-dom"
import { FaAppStore, FaBuilding, FaUsersCog } from "react-icons/fa"
import { AiFillDashboard, AiOutlineUser } from "react-icons/ai"
import { DollarOutlined, UserOutlined } from '@ant-design/icons';
import MenuDivider from "antd/lib/menu/MenuDivider";
const { SubMenu } = Menu;
const { Sider } = Layout;

/**
 *
 *
 * @export
 * @class Admin_Sidebar
 * @extends {Component}
 */
class AdminSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false
        }
    }


    /**
     *
     *
     * @memberof AdminSidebar
     */
    toogle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    render() {
        return (
            <Sider
                style={this.state.collapsed ? {position: "absolute", height: "100vh", backgroundColor:"#ffffff", zIndex:1000, boxShadow: "0px 30px 60px rgba(32, 56, 85, 0.15)"}: null}
                theme="light"
                width={250}
                collapsedWidth={0}
                breakpoint="lg"
                onBreakpoint={broken => {
                    this.setState({ collapsed: broken });
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
                className="custom-sider customer"
            >
                <Menu
                    mode="inline"
                    theme="light"
                    className="heigth-100 "
                    style={{ borderRight: 0 }}
                >
                    <Menu.Item key="start" onClick={() => this.props.navigate("/customer", { replace: true })} style={{ height: 100, marginBottom: "2em" }} >
                        <img src="/img/logotext.svg" />
                    </Menu.Item>
                    <Menu.Item key="dashboard" icon={<AiFillDashboard style={{ fontSize: 20 }} />} onClick={() => this.props.navigate("/customer", { replace: true })}>
                        Inicio
                    </Menu.Item>
                    <MenuDivider />
                    <Menu.Item key="compras" icon={<DollarOutlined />} onClick={() => this.props.navigate("/customer/compras", { replace: true })}>
                        Mis compras
                    </Menu.Item>
                    <MenuDivider />
                    <Menu.Item key="cuenta" icon={<UserOutlined />} onClick={() => this.props.navigate("/customer/cuenta", { replace: true })}>
                        Cuenta
                    </Menu.Item>
                </Menu>
            </Sider>
        )
    }
}

export default function View(props) {
    return <AdminSidebar navigate={useNavigate()} {...props} />
}
