import React, { useEffect, useContext } from 'react';
import { useState } from 'react';
import '../../Styles/Public/modal.scss'
import { Row, message, Typography, Space, Button, Col, Input, InputNumber, Modal, Select, Steps, Form, Divider } from 'antd';
import axios from 'axios';
import { Wheel } from 'react-custom-roulette';
import { useCookies } from 'react-cookie';
import TagBoleto from './TagBoleto';
import { Carrito, SetCarrito } from '../../Hooks/Carrito'
import { Guest } from '../../Hooks/Guest'
import { User } from '../../Hooks/Logged'
import ModalGuest from './ModalGuest';

const { Text, Title } = Typography;
const { Option } = Select;



const FormPreguntas = ({ setBoletos, cantidad }) => {
	return (
		<Form layout='vertical'>
			<Row style={{ justifyContent: "center" }}>
				<Title level={3}>Ruleta de la Suerte</Title>
			</Row>
			<Divider />
			<Row justify="center" gutter={[16, 16]}>
				<Col span={12}>
					<Form.Item
						style={{ width: "100%" }}
						label={<Title level={5} >Indica la cantidad de boletos</Title>}
					>
						<InputNumber
							min={1}
							size="large"
							max={cantidad}
							defaultValue={1}
							onChange={(e) => setBoletos(e)}
							// className="width-100"
							style={{ borderRadius: "8px", width: "100%" }}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						style={{ width: "100%" }}
						label={<Title level={5} >Selecciona tu Signo Zodiacal</Title>}
					>
						<Select
							size="large"
							className="width-100"
							options={[
								{ value: 'Aries', label: 'Aries' },
								{ value: 'Tauro', label: 'Tauro' },
								{ value: 'Géminis', label: 'Géminis' },
								{ value: 'Cáncer', label: 'Cáncer' },
								{ value: 'Leo', label: 'Leo' },
								{ value: 'Virgo', label: 'Virgo' },
								{ value: 'Libra', label: 'Libra' },
								{ value: 'Escorpio', label: 'Escorpio' },
								{ value: 'Sagitario', label: 'Sagitario' },
								{ value: 'Capricornio', label: 'Capricornio' },
								{ value: 'Acuario', label: 'Acuario' },
								{ value: 'Piscis', label: 'Piscis' },
							]}
						/>
					</Form.Item>

				</Col>
			</Row>
		</Form>
	);


}

const FormSpin = (props) => {


	const [mustSpin, setMustSpin] = useState(false);

	return (
		<>
			<Row justify="center">
				<Col span={20} className="mt-1 mb-1 center">
					<Text style={{ fontSize: "24px", textAlign: "center" }}>¡Prueba tu suerte y gira la ruleta!<strong>¡Suerte!</strong></Text>
				</Col>
				<Col span={18} className='roulette-container'>
					<Wheel
						mustStartSpinning={mustSpin}
						spinDuration={[0.2]}
						prizeNumber={2}
						data={

							// new Array(12).fill(null).map((e, index) => ({
							// 	option: index.toString(),
							// 	backgroundColor: (index % 2 == 0) ? "#FF3690" : ""
							// }))
							[
								{ option: '0', style: { backgroundColor: "#F26513" } },
								{ option: '1', style: { backgroundColor: "#3E3E3E" } },
								{ option: '2', style: { backgroundColor: "#F26513" } },
								{ option: '3', style: { backgroundColor: "#3E3E3E" } },
								{ option: '4', style: { backgroundColor: "#F26513" } },
								{ option: '5', style: { backgroundColor: "#3E3E3E" } },
								{ option: '6', style: { backgroundColor: "#F26513" } },
								{ option: '7', style: { backgroundColor: "#3E3E3E" } },
								{ option: '8', style: { backgroundColor: "#F26513" } },
								{ option: '9', style: { backgroundColor: "#3E3E3E" } },
								{ option: '10', style: { backgroundColor: "#F26513" } },
								{ option: '11', style: { backgroundColor: "#3E3E3E" } },
							]
						}
						onStopSpinning={(e) => {
							setMustSpin(false);
							props.handleSpinClick();
						}}
						outerBorderWidth={[3]}
						outerBorderColor={"gray"}
						innerBorderWidth={[2]}
						radiusLineColor={"transparent"}
						textColors={"transparent"}
					/>
				</Col>
			</Row>
			<Row justify={"center"} align={"middle"} className="mt-3">
				<Col xs={24} md={20} className='center'>
					<Button className='boletos width-100' style={{ height: "50px" }} onClick={() => setMustSpin(true)}>
						Girar
					</Button>
				</Col>
			</Row>
		</>
	);


}

const PreviewBoletos = (props) => {
	return (<>
		<Row justify={"center"} gutter={[16, 16]} className="width-100 mt-2">

			{props?.boletos?.map((obj) => {
				return <Col xs={12} md={8}>
					<TagBoleto width="160" height="85" numero={obj} />
				</Col>
			})}


		</Row>
		<Row justify={"center"} align={"middle"} className="mt-3" gutter={12}>
			<Col xs={12} md={10} className='center'>
				<Button className='boletos width-100' style={{ height: "50px" }} onClick={() => props.add()}>
					Agregar al carrito
				</Button>
			</Col>
		</Row>
	</>

	)
}

const ModalRuleta = props => {

	let carrito = useContext(Carrito);
	let setCarrito = useContext(SetCarrito)
	let guest = useContext(Guest)
	let user = useContext(User)
	const [cookies, setCookie] = useCookies(['cart']);
	const [data, setData] = useState([])
	const [boletos, setBoletos] = useState(1)

	const [modalGuestVisible, setModalGuestVisible] = useState(false);

	//Obtiene los boletos disponibles ya formateados para la ruleta desde el backend
	function getDisponibles() {
		if (props.sorteo_id == null) return null
		axios.get('/info/' + props.sorteo_id + '/disponibles', {
			params: {
				//Se envia la cantidad de boletos a obtener, si no se envia se obtienen 200
				cantidad: props.cantidad ?? boletos,
			}
		})
			.then(({ data }) => {
				console.log("data ruleta disponibles", data);
				setData(data)
			})
			.catch(error => {
				console.log("error", error);
				message.error(error?.response?.data ?? "Error al obtener la información")
			})
	}


	//Agregar al carrito el boleto generado
	async function addToCart(numeros, costo, sorteo_id) {
		console.log("addToCart", numeros, costo, sorteo_id, user, guest)

		//Si no hay invitado o usuario loggeado, se muestra el modal para agregar invitado
		if (guest == null || user == null) {

			if (user) {

			}

			else {
				setModalGuestVisible(true)
				return;
			}


		}

		const cart = carrito ?? [];
		let success = 0

		for (let i in numeros) {
			let boleto = numeros[i]
			console.log(boleto)
			const index = carrito.findIndex(item => item.numero == boleto);

			if (index == -1) {
				cart.push({ numero: boleto, costo: costo, sorteo_id: sorteo_id })
				success += 1
			} else {
				console.log("ya existe")
				message.info(`Boleto no. ${boleto} ya existe en el carrito`)
				return;
			}
		}

		message.info(`Se agregaron ${success} boletos`)




		//Se agrega el boleto al carrito en la base de datos, si no existe lo crea
		await axios.post('/compra', {
			...(cookies.cart != "null" || cookies.cart != "undefined") ? { venta_id: cookies.cart } : {},
			boletos: cart.map(item => item.numero),
			sorteo_id: sorteo_id,
			cantidad: cart.length,
			costo_boleto: costo,
			monto: cart.length * costo,
			datos_cliente: guest
		}).then(({ data }) => {
			console.log(data)
			setCookie("cart", data._id)
		}).catch((error) => {
			console.log(error)
			for (let i in numeros) {
				let boleto = numeros[i]
				let indexBoleto = carrito.findIndex(item => item.numero == boleto)
				cart.splice(indexBoleto, 1)
			}
			message.error(error.response.data ?? "Ocurrio un error al agregar el boleto al carrito")
		})

		setCarrito(cart)
	}



	//Se ejecuta al hacer click en el boton de girar, genera un numero al azar y lo asigna a la ruleta
	const handleSpinClick = () => {

		//Si no hay invitado o usuario loggeado, se muestra el modal para agregar invitado
		if (guest == null || user == null) {

			if (user) {

			}
			else {
				setModalGuestVisible(true)
				return;
			}

			// setModalGuestVisible(true)
			// return;
		}
		console.clear()

		getDisponibles()

	};



	return (
		<>
			<Modal
				visible={props.visible}
				onCancel={props.onClose}
				width={600}
				footer={null}
				closable={false}
				destroyOnClose={true}
				className="modal-spin"
				getContainer={() => document.getElementById("root")}
			>
				<FormPreguntas
					cantidad={props.cantidad}
					setBoletos={(boletos) => { setBoletos(boletos) }}
				/>
				<FormSpin handleSpinClick={handleSpinClick} />
				<PreviewBoletos boletos={data} girardeNuevo={() => {

				}} add={() => {

					addToCart(data, props.costo, props.sorteo_id)
					props.onClose()
				}} />
			</Modal>
			<ModalGuest
				visible={modalGuestVisible}
				onSuccess={() => {
					message.success("¡Registro exitoso!, ya puede seleccionar boletos.")
					setModalGuestVisible(false)
				}}
				onClose={() => {
					setModalGuestVisible(false)
				}}
			/>
		</>

	);
}

export default ModalRuleta;