
import { Drawer, List, message, Card, Row, Col, Typography, Form, Input, Modal, Divider } from "antd";
import React, { Component, createRef, useContext, useEffect, useState } from "react";
import { Carrito, SetCarrito } from "../../Hooks/Carrito";
import { Button } from "antd";
import { User, SetUser } from "../../Hooks/Logged";
import { FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { BsEnvelope, BsFacebook, BsTelephone } from "react-icons/bs";
import PhoneInput from "../Widgets/Inputs/PhoneInput";
import { FaWhatsapp } from "react-icons/fa";

import "../../Styles/Public/carrito.scss";
import { Guest, SetGuest } from "../../Hooks/Guest";

const { Text, Title, Paragraph } = Typography

class CarritoDrawer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            costo: 0
        }
    }

    componentDidMount() {
        this.setState({ total: this.calcularTotal() })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cart != this.props.cart) {
            this.setState({ total: this.calcularTotal() })
        }

    }

    calcularTotal = () => {
        let total = 0;
        if (Array.isArray(this.props.cart)) {
            this.props.cart.forEach(item => {
                total += item.costo
            })
            return total
        } else return 0

    }

    render() {
        return <>
            <List
                className="carrito"
                dataSource={Array.isArray(this.props.cart) ? this.props.cart : []}
                emptyText="No hay boletos en el carrito"
                renderItem={item => <List.Item>
                    <Card className="width-100 item-boleto">
                        <Row>
                            <Col xs={24} md={12}>
                                <Text className="title">NÚMERO DE PARTICIPACIÓN</Text>
                            </Col>
                            <Col xs={24} md={12}>
                                <Row justify={"end"}>
                                    <Text className="title">COSTO</Text>
                                </Row>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} md={12}>
                                <Text className="numero">{item?.numero}</Text>
                            </Col>
                            <Col xs={24} md={12}>
                                <Row justify={"end"} align={"bottom"}>
                                    <Text className="costo">${item?.costo?.toMoney(true)} USD</Text>
                                </Row>

                            </Col>
                        </Row>
                    </Card>
                </List.Item>}
            />
            <Divider />
            <Row className="mb-3">
                <Col span={12}>
                    <Text strong style={{ fontSize: "22px" }}>Cantidad</Text>
                </Col>
                <Col span={12} className="center" style={{ fontSize: "22px" }}>
                    <Text strong>Total</Text>
                </Col>
                <Col span={12}>
                    <Text strong>{this.props?.cart?.length} boleto(s)</Text>
                </Col>
                <Col span={12} className="center">
                    <Text strong>$ {(this.calcularTotal()).toMoney(true)}  USD </Text>
                </Col>
            </Row>




        </>
    }
}

class Login extends Component {

    handleSubmit = (values) => {
        this.setState({ loading: true })
        axios.post('/login', {
            email: values.email,
            password: values.password
        })
            .then(({ data, headers }) => {
                const { setUser } = this.props;

                axios.defaults.headers.post["Authorization"] = headers.authorization;

                sessionStorage.setItem('token', headers.authorization);
                axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

                setUser(data.user);

                console.log("data.user.cliente_id", data.user);

                this.props.nextStep()


            })
            .catch((error) => {

                Modal.error({ title: "Credenciales incorrectas" })

                this.setState({ loading: false });
            })
        //this.redirectTo('/admin/dashboard');
    };

    /**
     * @memberof Login
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
    **/
    loginFB = () => {
        if (!window.FB) {
            alert("No hay conexión con Facebook")
            return
        }

        window.FB.getLoginStatus(response => {
            if (response.status === "connected") {
                this.getInfoFB(response)
            } else {
                window.FB.login(result => {
                    if (result.status === "connected")
                        this.getInfoFB(result)
                }, { scope: 'public_profile,email' })
            }
        })
    };


    /**
     * @memberof Login
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
    **/
    getInfoFB = (response) => {
        window.FB.api('/me', 'GET', { "fields": "email,first_name,middle_name,last_name" }, (me) => {
            axios.post('/facebook', {
                ...me,
                authResponse: response.authResponse
            })
                .then(({ data, headers }) => {

                    const { setUser } = this.props;

                    axios.defaults.headers.post["Authorization"] = headers.authorization;

                    sessionStorage.setItem('token', headers.authorization);
                    axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

                    setUser(data.user);

                    this.props.nextStep()

                })
                .catch(error => {
                    message.error(error.response?.data?.message ?? "Error al iniciar sesión con Facebook")
                })
        })
    };

    render() {
        return (
            <div className='login-form-container'>
                <Form onFinish={this.handleSubmit} layout={"vertical"} requiredMark={false} className='login-form'>
                    <Title level={3} className="h3-title">¡Por favor inicie sesión para continuar con la compra!</Title>
                    <Form.Item
                        className='input-email'
                        name="email"
                        label="Correo electrónico"
                        rules={[
                            { required: true, message: 'Ingrese su correo' }
                        ]}>
                        <Input placeholder="ejemplo@mail.com" size="large" className="input-login" />
                    </Form.Item>
                    <Form.Item
                        label="Contraseña"
                        name="password"
                        rules={[{ required: true, message: 'Ingrese su contraseña' }]}>
                        <Input type="password" placeholder="********" size="large" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block size="large" >Iniciar sesión</Button>
                    </Form.Item>
                    <Button
                        type="primary"
                        block
                        size="large"
                        onClick={this.loginFB}
                        icon={<FaFacebook style={{ fontSize: "30px", marginRight: "1rem" }} />}
                        className="center btn-facebook"
                    >Continuar con Facebook</Button>
                    <Text>¿No tienes cuenta? <Link onClick={() => this.props.nextStep(true)}>Registrate</Link></Text>
                </Form>
            </div>
        );
    }
}

class Register extends Component {

    formRef = createRef()

    componentDidMount() {
        if (this.props.guest) {
            this.formRef.current?.setFieldsValue(this.props.guest)
        }
    }

    handleSubmit = (values) => {
        this.props.setGuest({ ...values, invitado: true })
        setTimeout(() => {
            console.log("values", values)
            this.props.nextStep()
        }, 500);

    }

    // handleSubmit = (values) => {
    //     this.setState({ loading: true })
    //     axios.post('/register', values)
    //         .then(({ data, headers }) => {

    //             const { setUser } = this.props;
    //             axios.defaults.headers.post["Authorization"] = headers.authorization;
    //             sessionStorage.setItem('token', headers.authorization);
    //             axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

    //             setUser(data.user);

    //             this.props.nextStep()

    //         })
    //         .catch((error) => {
    //             console.log("error", error);
    //             Modal.error({
    //                 title: "Ocurrio un error en el registro",
    //                 content: error?.response?.data?.message ?? "Intente mas tarde"
    //             })
    //             this.setState({ loading: false });
    //         })
    // };
    generateWhatsapp = async () => {

        // console.log("this.formRef", )
        this.formRef.current.validateFields()
            .then(e => {
                console.log("E", e)
                let nombreSorteo = this.props.sorteo
                let formValues = this.formRef?.current?.getFieldsValue(true)
                if (!formValues.nombre || !formValues.apellido_paterno || this.props.cart.length === 0) {

                    return
                }
                let mensaje = `¡Si!. Mi nombre es ${formValues?.nombre} ${formValues?.apellido_paterno} ${formValues?.apellido_materno}, y estoy interesado en el Sorteo ${nombreSorteo} con los boletos: ${this.props?.cart?.map(item => item.numero).join(", ")}`
                window.open(`https://wa.me/15312325998?text=${encodeURIComponent(mensaje)}`)
            })
            .catch(e => {
                message.error("Por favor, complete todos los campos")
            })

    }

    render() {



        return (
            <Row justify={"center"} className='layout-login'>

                <Col span={24}>
                    <div >
                        <Form
                            onFinish={this.handleSubmit}
                            layout={"vertical"}
                            requiredMark={false}
                            className='login-form'
                            ref={this.formRef}
                        >
                            <Title level={3} className="h3-title mb-3">Ingresa tu información para continuar</Title>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <Form.Item
                                        name="nombre"
                                        label="Nombre(s)"
                                        rules={[
                                            { required: true, message: 'Ingrese su Nombre' }
                                        ]}>
                                        <Input size="large" className="input-login" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="apellido_paterno"
                                        label="Apellido Paterno"
                                        rules={[
                                            { required: true, message: 'Ingrese su apellido' }
                                        ]}>
                                        <Input size="large" className="input-login" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="apellido_materno"
                                        label="Apellido Materno"
                                    >
                                        <Input size="large" className="input-login" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="email"
                                        label="Correo electrónico"
                                    // rules={[
                                    //     { required: true, message: 'Ingrese su correo' }
                                    // ]}
                                    >
                                        <Input placeholder="ejemplo@mail.com" size="large" className="input-login" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="telefono"
                                        label="Teléfono"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Ingrese el Teléfono',
                                            }
                                        ]}
                                    >
                                        <PhoneInput />
                                    </Form.Item>
                                </Col>
                                {/* <Col span={24}>
                                    <Form.Item
                                        label="Contraseña"
                                        name="password"
                                        hasFeedback
                                        rules={[
                                            { required: true, message: 'Ingrese su contraseña' }
                                        ]}
                                    >
                                        <Input.Password placeholder="********" size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="confirm"
                                        label="Confirmar Contraseña"
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor confirme su contraseña',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Las contraseñas no coinciden'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password placeholder="********" size="large" />
                                    </Form.Item>
                                </Col> */}
                            </Row>
                            <Row gutter={[16, 0]}>
                                <Col span={12}>
                                    <Button 
                                        block
                                        type="primary" size="large" className="btn-whatsapp" onClick={this.generateWhatsapp} 
                                        icon={ <FaWhatsapp  style={{ position: "relative", right: 5, fontSize: 18, bottom: -1 }} />}
                                    ><strong style={{ position: "relative", bottom: 2 }}>Hablar por Whatsapp</strong>
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" block size="large" >Continuar como invitado</Button>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Text>¿Ya tienes cuenta? <Link onClick={() => this.props.nextStep()}>Iniciar sesión</Link></Text>
                        </Form>
                    </div>
                </Col>

            </Row>
        )
    }
}

class Pago extends Component {

    componentDidMount() {
        this.handleSubmit()
    }

    handleSubmit = (values) => {
        console.log("cart", this.props.cart)
        console.log("user", this.props.user)
        console.log("guest", this.props.guest)
        this.setState({ loading: true })
        axios.post('/pago', {
            venta_id: this.props.cookies.cart,
            cliente_id: this.props.user.cliente_id,
            boletos: this.props.cart.map(item => item.numero),
            cantidad: this.props.cart.length,
            costo_boleto: this.props.cart[0].costo,
            monto: this.props.cart.length * this.props.cart[0].costo,
            datos_cliente: this.props.guest
        }).then(({ data }) => {
            console.log(data)
            // message.success("")
            this.props.resetCart()
            message.success("Compra realizada con exito")
            // Modal.success({ title: "¡Gracias por participar con nosotros!", content: <div> le deseamos suerte </div> })
        }).catch((error) => {
            console.log(error)
            message.error("Ocurrio un error al procesar su pago")
        })
    }


    render() {

        const BSSocialStyle = {
            textAlign: "center",
            display: "block",
            margin: "auto",
            fontSize: 24
        }

        return (
            <div className='login-form-container'>
                <Title level={3} className="h3-title">Información del Pago</Title>

                <Title level={5}>Puedes realizar el pago de tu participación en el sorteo a través Zelle o mediante depósito bancario</Title>
                <Title level={5}>¡Recuerda enviarnos tu recibo de pago para confirmar tu compra! </Title>
                <Row>
                    <Col span={8}>
                        <a href='tel:+15312325998' target="_blank">
                            <BsFacebook style={BSSocialStyle} />
                            <span style={{ ...BSSocialStyle, fontSize: 14, }}>Alejandro Hurtado</span>
                        </a>
                    </Col>
                    <Col span={8}>
                        <a href='tel:+15312325998' target="_blank">
                            <BsTelephone style={BSSocialStyle} />
                            <span style={{ ...BSSocialStyle, fontSize: 14, }}>+1 (531) 232-5998</span>
                        </a>
                    </Col>
                    <Col span={8}>
                        <a href='mailto:fugapadurango2023@gmail.com' target="_blank">
                            <BsEnvelope style={BSSocialStyle} />
                            <span style={{ ...BSSocialStyle, fontSize: 10, }}>fugapadurango2023@gmail.com</span>
                        </a>
                    </Col>
                </Row>
                <Divider />
                <Title level={5} style={{ textAlign: "center" }}>FUGA PA' DURANGO LLC</Title>
                <Title level={5} style={{ textAlign: "center" }}><i>531-232-5998</i></Title>
                <img src="/img/zelle-fuga-pa-durango.svg" alt="FUGA PA' DURANGO LLC" style={{ maxWidth: 200, width: "100%", margin: "auto", display: "block" }} />
                <Divider />
                <Title level={5} style={{ textAlign: "center" }}>BANCO: BBVA</Title>
                <img src="/img/bbva.svg" alt="BBVA" style={{ maxWidth: 200, width: "100%", margin: "auto", display: "block" }} />
                <Title level={5} style={{ textAlign: "center", marginTop: "1em" }}>CUENTA: 4152 3141 4002 1174</Title>
                <Divider />
                <Title level={5} style={{ textAlign: "center" }}>BANCO: SPIN BY OXXO</Title>
                <img src="/img/spin.jpeg" alt="SPIN" style={{ maxWidth: 200, width: "100%", margin: "auto", display: "block" }} />
                <Title level={5} style={{ textAlign: "center" }}>CUENTA: 4217 4700 2938 6212</Title>
                {/* <Form onFinish={this.handleSubmit} layout={"vertical"} requiredMark={false} className='login-form'> */}
                {/* <Form.Item
                        name="nombre"
                        label="Nombre del tarjetahabiente"
                        rules={[
                            { required: true, message: 'Por favor ingrese el nombre' }
                        ]}>
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        label="Numero de tarjeta"
                        name="tarjeta"
                        rules={[{ required: true, message: 'Ingrese el numero de tarjeta' }]}>
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        label="Fecha de expiración"
                        name="expiracion"
                        rules={[{ required: true, message: 'Ingrese el la fecha de expiracion' }]}>
                        <Input size="large" placeholder="MM/AAAA" />
                    </Form.Item>
                    <Form.Item
                        label="CVV"
                        name="cvv"
                        rules={[{ required: true, message: 'Ingrese el numero de seguridad' }]}>
                        <Input size="large" placeholder="XXX" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block size="large" >Pagar</Button>
                    </Form.Item> */}

                {/* </Form> */}
            </div>
        );
    }
}

export default function Vista(props) {

    let cart = useContext(Carrito);
    let setCart = useContext(SetCarrito)
    let user = useContext(User)
    let guest = useContext(Guest)
    let setGuest = useContext(SetGuest)
    const setUser = useContext(SetUser)
    const [cookies, setCookie] = useCookies(['cart']);
    const [step, setStep] = useState(1)




    useEffect(() => {
        if (cookies.cart !== undefined || cookies.cart !== null) {
            axios.post('/carrito', {
                venta_id: cookies.cart,

            }).then(({ data }) => {
                console.log("data carrito", data)
                console.log(data)
                setCart(data.cart)
                setGuest(data.guest)

            }).catch((error) => {
                console.log(error)

            })
        }
    }, [])


    let nextStep = (back) => {

        if (back) {
            setStep(step - 1)
            return
        }

        if (step === 1 && cart.length === 0) {
            message.error("No hay boletos en el carrito")
            return
        }
        else if (step === 1 && !user) {
            setStep(step + 1)

        } else if (step === 1 && user) {
            setStep(step + 3)
        } else if (step === 2 && guest.invitado) {
            console.log("next step", guest)
            setStep(step + 2)
        }

        else {
            setStep(step + 1)
        }

        if (step == 4) {
            resetCart()
            props.onClose()
        }

    }

    let resetCart = () => {
        setCart([])
        setCookie('cart', null)
        // setStep(1)
    }

    return <Drawer
        className="carrito"
        open={props.open}
        onClose={() => {

            props.onClose()
            setStep(1)

        }}
        title="Carrito de compras"
        extra={<Button onClick={() => { nextStep() }}>Pagar ahora</Button>}
        width={500}
        getContainer={() => document.getElementById("root")}
    >
        {step === 1 && <CarritoDrawer {...props} cart={cart} cookies={cookies} setCookie={setCookie} nextStep={nextStep} />}
        {step === 2 && <Register {...props} user={user} cart={cart} guest={guest} setGuest={setGuest} setUser={setUser} nextStep={nextStep} sorteo={props.sorteo} />}
        {step === 3 && <Login {...props} setUser={setUser} guest={guest} nextStep={nextStep} />}
        {step === 4 && <Pago {...props} nextStep={nextStep} cookies={cookies} cart={cart} guest={guest} resetCart={resetCart} user={user} />}
    </Drawer>

}