import React from 'react'
import { Layout, Col, Row, Typography, List, Card, Space, Button, message, Form, Input, Spin } from "antd";
import axios from 'axios';
import moment from 'moment'
import { AiOutlineEye } from 'react-icons/ai';

//Componentes
import { IconEdit } from '../Widgets/Iconos';
import PhoneInput from "../Widgets/Inputs/PhoneInput";
//Modal
import ModalVentasDetalle from "../Admin/Ventas/ModalDetalle"


const { Content } = Layout;
const { Title, Text } = Typography;

export default class Dashboard extends React.Component {

	/**
	 *Creates an instance of Usuarios.
	 * @param {*} props
	 * @memberof Usuarios
	 */
	constructor(props) {
		super(props)
		this.state = {

			loading: false,
			ventas: {
				data: [],
				limit: 10,
				page: 1,

				total: 0,
				pages: 0,
				filters: []

			},
			venta_id: null
		}
	}

	componentDidMount() {
		this.getCliente()
	}

	formRef = React.createRef()

	/**
	*
	* @memberof Ventas
	* @method getCliente
	* @description Obtiene la informacion del cliente logeado
	*/
	getCliente = () => {

		this.setState({ loading: true })

		axios.get('/customer/cliente')
			.then(({ data }) => {
				console.log("data", data);
				this.formRef.current.setFieldsValue({ ...data })
			})
			.catch(res => {
				message.error(res?.response?.data?.message ?? "Error al obtener la información")
			})
			.finally(() => this.setState({ loading: false }))
	}


	/**
	*
	* @memberof Ventas
	* @method upadateCliente
	* @description Actialzia la información del cliente
	*/
	upadateCliente = (values) => {

		this.setState({ loading: true })

		axios.put('/customer/cliente', values)
			.then(({ data }) => {
				message.success("Información actualizada")
			})
			.catch(res => {
				message.error(res?.response?.data?.message ?? "Error al obtener la información")
			})
			.finally(() => this.setState({ loading: false }))
	}

	render() {
		return (
			<>
				<Content className="p-1">
					<Row gutter={[1, 16]} className="pt-1">
						<Col span={24} className="customer-header-page">
							<Title level={3}>Mi Cuenta</Title>
						</Col>

						<Col span={24} className="customer-body-page">
							<Spin spinning={this.state.loading}>
								<Form
									id="form-cliente"
									layout={"vertical"}
									onFinish={this.upadateCliente}
									ref={this.formRef}
								>
									<Row justify="center" className="center" gutter={[16, 0]}>

										<Col span={20}>

											<Form.Item
												name="nombre"
												label="Nombre"
												rules={[
													{
														required: true,
														message: 'Ingrese el nombre',
													}, {
														max: 50,
														message: "Máximo 50 caracteres"
													}
												]}
											>
												<Input />
											</Form.Item>
										</Col>
										<Col span={10}>

											<Form.Item
												name="apellido_paterno"
												label="Apellido Paterno"
												rules={[
													{
														required: true,
														message: 'Ingrese el apellido paterno',
													}, {
														max: 50,
														message: "Máximo 50 caracteres"
													}
												]}
											>
												<Input />
											</Form.Item>
										</Col>
										<Col span={10}>

											<Form.Item
												name="apellido_materno"
												label="Apellido Materno"
												rules={[
													{
														max: 50,
														message: "Máximo 50 caracteres"
													}
												]}
											>
												<Input />
											</Form.Item>

										</Col>
										<Col span={20}>
											<Form.Item
												name="email"
												label="E-mail"
												rules={[
													{
														type: 'email',
														message: 'Email no valido',
													},
													{
														required: true,
														message: 'Ingrese el Email',
													}, {
														max: 50,
														message: "Máximo 50 caracteres"
													}
												]}
											>
												<Input />
											</Form.Item>
											<Form.Item
												name="telefono"
												label="Teléfono"
												rules={[
													{
														required: false,
														message: 'Ingrese el Teléfono',
													}
												]}
											>
												<PhoneInput />
											</Form.Item>
											<Form.Item
												name="ciudad"
												label="Ciudad"
												rules={[
													{
														max: 50,
														message: "Máximo 50 caracteres"
													}
												]}
											>
												<Input />
											</Form.Item>
											<Form.Item
												name="codigo_postal"
												label="Código postal"
												rules={[
													{
														max: 10,
														message: "Máximo 10 caracteres"
													}
												]}
											>
												<Input />
											</Form.Item>
										</Col>
										<Col span={20}>
											<Form.Item>
												<Button type="primary" htmlType="submit" block>
													Guardar
												</Button>
											</Form.Item>
										</Col>
									</Row>
								</Form>
							</Spin>

						</Col>
					</Row>
				</Content>
			</>
		)
	}
} 