import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Modal, Spin, PageHeader, Layout, Space, message, Card, List } from 'antd'
import axios from 'axios';
import moment from 'moment'

//componentes
import Logged from "../../../../Hooks/Logged";
import { AiOutlineEye } from 'react-icons/ai';
import { ButtonView } from "../../../Widgets/Buttons";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import { IconDelete, IconEdit, IconMail, IconEye } from '../../../Widgets/Iconos';

//modal
import ModalGanadores from './ModalGanadores'


const { Content } = Layout;
const { Text } = Typography

/**
 *
 *
 * @export
 * @class VentasTab
 * @extends {Component}
 * @description Vista donde se listan todos las ventas
 */
export default class VentasTab extends Component {
    static contextType = Logged;


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            ganadores: [],
            venta_id: null
        }
    }

    componentDidMount() {
        
        if(this.props.sorteo_id)
            this.getGanadores()

    }

   
    /**
    * @method getGanadores
    * @description Obtiene a los ganadores del sorteo
    */
    getGanadores = () => {
        this.setState({ loading: true })
        axios.get('/sorteo/ganadores/'+this.props.sorteo_id)
        .then(({data}) => {
            console.clear()
            console.log("data", data.ganadores);
            this.setState({ganadores: data.ganadores})
        })
        .catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener la información")
        }).finally(()=>this.setState({loading: false}))
    } 

    render() {

        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin Ganadores" }}
                    dataSource={this.state.ganadores}
                    header={<Row className="component-list-header width-100 pr-1 pl-1"  gutter={[16, 16]}>
                        <Col xs={24} lg={8} className="center">
                            <Text strong>Cliente</Text>
                        </Col>
                        <Col xs={24} lg={8} className="center">
                            <Text strong>Boleto</Text>
                        </Col>
                        <Col xs={24} lg={8} className="center">
                            <Text strong>Premio</Text>
                        </Col>
                        
                    </Row>}

                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list">
                                <Row className="width-100 " gutter={[16, 16]}>

                                    <Col xs={24} lg={8} className="center">
                                        <Text>{item?.boleto_id?.cliente_id?.nombre} {item?.boleto_id?.cliente_id?.apellido_paterno} {item?.boleto_id?.cliente_id?.apellido_materno ?? ""}</Text>
                                    </Col>
                                    <Col xs={24} lg={8} className="center">
                                        <Text>{item.boleto_id?.numero}</Text>
                                    </Col>
                                    <Col xs={24} lg={8} className="center">
                                        <Text>{item.premio_id?.nombre ?? "-"}</Text>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
                <FloatingButton onClick={()=>this.setState({modal_visible: true})} disabled={this.state.ganadores.length > 0}/>
                <ModalGanadores
                    visible={this.state.modal_visible}
                    onClose={(flag)=>{
                        this.setState({modal_visible: false})
                        if(flag === true){
                            this.getGanadores()
                            this.props.getSorteo()
                        }

                    }}
                    sorteo_id={this.props.sorteo_id}
                />
            </>
        )
    }
}
