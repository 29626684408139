import React, { Component, useContext } from 'react'
import { Layout, Row, Col, Typography, Button, Avatar, Image, Card, Divider, Tabs, Space, message } from 'antd'

import { FaSquareFull } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { BsStar, BsStarFill } from 'react-icons/bs'
import { HashLink } from 'react-router-hash-link'
import Header from './Header'
import Footer from './Footer'
import Slider from 'react-slick'
import moment from 'moment'

import '../../Styles/Public/landing.scss'

import { CarroIcon, ChevronLeft, ChevronRight } from '../Widgets/Iconos'
import { Rating } from 'react-simple-star-rating'
import axios from 'axios'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import rehypeRaw from 'rehype-raw'
import Boletos from './Boletos'
import Recientes from './Recientes'
import ModalRuleta from './ModalRuleta'
import ModalGuest from './ModalGuest'

import Guest from "../../Hooks/Guest";
import User from "../../Hooks/Logged";


const { Content } = Layout
const { Text, Paragraph } = Typography
class Landing extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			visible_modal: false,
			visible: false,
			sorteo: {
				disponibles: 0,
				cantidad_boletos: 0,
				banner: '/img/slider/slider1.png',
				descripciones: [null],
			},
			proyecto_id: null,
			toggleCarrito: false,
			width: 0,
			height: 0,
			currentIndex: 0,
		};
	}



	// Slider methods
	next() {
		this.slider.slickNext()
	}

	previous() {
		this.slider.slickPrev()
	}
	///////

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		console.log(this.props)
		this.setState({ width: window.innerWidth, height: window.innerHeight })
		this.getInfo()
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight })
	}

	getInfo() {
		axios.get('/info/' + this.props.params.unique_url)
			.then(({ data }) => {
				if (data.banner) data.banner = `${axios.defaults.baseURL}/info/${data._id}/banner`
				if (Array.isArray(data.imagenes_celular)) data.imagenes_celular = data.imagenes_celular.map(file => axios.defaults.baseURL + '/info/' + this.props.params.unique_url + "?imagenes=" + file.file + "&Authorization=" + sessionStorage.getItem("token"))//`${axios.defaults.baseURL}/info/${data._id}/banner`
				if (Array.isArray(data.imagenes_escritorio)) data.imagenes_escritorio = data.imagenes_escritorio.map(file => axios.defaults.baseURL + '/info/' + this.props.params.unique_url + "?imagenes=" + file.file + "&Authorization=" + sessionStorage.getItem("token"))//`${axios.defaults.baseURL}/info/${data._id}/banner`
				if (Array.isArray(data.imagenes)) data.imagenes = data.imagenes.map(file => axios.defaults.baseURL + '/info/' + this.props.params.unique_url + "?imagenes=" + file.file + "&Authorization=" + sessionStorage.getItem("token"))//`${axios.defaults.baseURL}/info/${data._id}/banner`
				console.log(" X X X5555 ", data);
				this.setState({ sorteo: data })
			})
			.catch(error => {
				console.log("error", error);
				message.error(error?.response?.data?.message ?? "Error al obtener la información")
			}).finally(() => this.setState({ loading: false }))
	}

	render() {

		const sliderSettings = {
			dots: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 4000,
		}

		return (
			<Layout className="transparent landing">

				<Header toggleCarrito={this.state.toggleCarrito} sorteoActual={this.state.sorteo?.nombre} />


				<Slider ref={c => (this.slider = c)} className='slider-small-background' {...sliderSettings} afterChange={(newIndex) => this.setState({ currentIndex: newIndex })}>

					{((Array.isArray(this.state.sorteo?.imagenes_escritorio) && this.state.sorteo?.imagenes_escritorio.length > 0) || (Array.isArray(this.state.sorteo?.imagenes_celular) && this.state.sorteo?.imagenes_celular.length > 0)) ?

						this.state.sorteo.imagenes_escritorio.map((e, index) => <div>
							{/* <Image src={e} alt='auto-premio' /> */}
							<Image src={this.state.width > 1200 ? e : this.state.sorteo.imagenes_celular[index]} alt='auto-premio' onClick={() => this.setState({ visible: true })} preview={false} />
							{console.log(e)}
							{/* <picture>
									<source media="(max-width: 600px)" srcSet={this.state.sorteo.imagenes_celular[index]} />
									<source media="(max-width: 1200px)" srcSet={e} />
									<img src={e} alt='auto-premio' />
								</picture> */}
						</div>)
						:
						(Array.isArray(this.state.sorteo?.imagenes) && this.state.sorteo?.imagenes_escritorio.length > 0) ?

							this.state.sorteo.imagenes.map((e, index) => <div>
								<Image src={e} alt='auto-premio' onClick={() => this.setState({ visible: true })} preview={false} />

							</div>)
							:
							<div>
								<Image src={this.state.sorteo.banner} alt='auto-premio' onClick={() => this.setState({ visible: true })} preview={false} />
							</div>

					}
					{/* <div>
								<Image src={this.state.sorteo.banner} alt='auto-premio' />
							</div> */}

				</Slider>
				<div
					style={{
						display: 'none',
					}}
				>
					<Image.PreviewGroup preview={{ visible: this.state.visible, onVisibleChange: (vis) => this.setState({ visible: vis }) }}>
						{(Array.isArray(this.state.sorteo?.imagenes) && this.state.sorteo?.imagenes_escritorio.length > 0) ?

							this.state.sorteo.imagenes.map((e, index) => <div>
								<Image src={e} alt='auto-premio' onClick={() => this.setState({ visible: true })} />

							</div>)
							:
							<div>
								<Image src={this.state.sorteo.banner} alt='auto-premio' onClick={() => this.setState({ visible: true })} />
							</div>}
					</Image.PreviewGroup>
				</div>


				<Content className='slider-small-foreground allow-clicks'>

					<Row>
						<Col span={1} className='not-passthrough'>
							<Row align={"middle"} justify={"center"} className='half-height'>
								<button className='slider-button' onClick={this.previous.bind(this)}>
									<ChevronLeft />
								</button>
							</Row>

						</Col>
						<Col span={22}>
							<Row className='half-height pd-1' align={"middle"}>
								<Col>
									<Row>
										<FaSquareFull color='#40DDB6' className='mr-1' />
										<Text className='mensaje'>
											Tú podrías ser nuestro siguiente ganador
										</Text>
									</Row>
									<Row>
										<Text className='titulo'>
											{this.state.sorteo?.nombre}
										</Text>
									</Row>


									<Row>
										<Text className='subtitulo mt-2 mb-2'>
											{this.state.sorteo?.descripciones[this.state?.currentIndex] ?? this.state.sorteo?.descripcion}
										</Text>
									</Row>

									<Row className='not-passthrough'>
										<HashLink smooth to={`/evento/${this.state.sorteo.url}#boletos`}>
											<Button className='boletos' size='large'>
												Comprar Boletos
											</Button>
										</HashLink>

									</Row>
								</Col>
							</Row>
						</Col>
						<Col span={1} className='not-passthrough'>
							<Row align={"middle"} justify={"center"} className='half-height'>
								<button className='slider-button' onClick={this.next.bind(this)}>
									<ChevronRight />
								</button>
							</Row>

						</Col>
					</Row>
				</Content>
				<Row justify="center">
					<Content className='pd-2'>
						<Row>
							<Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
								<Row>
									<Card className='card-info'>
										Información General
									</Card>
								</Row>
								<Row>
									<Card className='card-general mt-2'>
										<Row>
											<Col span={24}>
												<Text className='titulo'> Fecha de Finalización </Text>
											</Col>
											<Col span={24}>
												<Text className='info'>{moment(this.state.sorteo?.fecha_finalizacion).format("LL")}</Text>
											</Col>
										</Row>
										<Divider />
										<Row>
											<Col span={24}>
												<Text className='titulo'> Location : </Text>
											</Col>
											<Col span={24}>
												<Text className='info' ellipsis={true}>{this.state.sorteo?.ciudad} {this.state.sorteo?.pais}</Text>
											</Col>
										</Row>
										<Divider />
										<Row>
											<Col span={24}>
												<Text className='titulo'> Disponibles: </Text>
											</Col>
											<Col span={24}>
												<Text className='info'>{this.state.sorteo?.disponibles?.toMoney(true)}</Text>
											</Col>
										</Row>
										<Divider />
										<Row>
											<Col span={24}>
												<Text className='titulo'> Sitio web : </Text>
											</Col>
											<Col span={24}>
												<Text className='info'>https://fugapadurango.com/evento/{this.state.sorteo?.url}</Text>
											</Col>
										</Row>
									</Card>
								</Row>
							</Col>
							<Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18} className="pl-2 pt-2">
								<ReactMarkdown rehypePlugins={[rehypeRaw]}>
									{this.state.sorteo.contenido}
								</ReactMarkdown>

							</Col>
						</Row>

					</Content>
				</Row>

				<Divider style={{ backgroundColor: "#F26513" }} />
				<Row justify={"center"}>
					<Content className='mt-1'>
						<Row>
							<Col span={24}>
								<Row align={"center"}>
									<Text className='titulo2'>
										¿Cómo puedo&nbsp;
									</Text>
									<Text className='titulo2-alt'>
										participar?
									</Text>
								</Row>
								<Row className='mt-3 pd-1' gutter={[16, 50]}>
									<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
										<Row>
											<Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<Row align={"middle"} className="fondo-elipsis center">
													<img src="/img/trofeo.png" alt="" />
												</Row>
											</Col>
											<Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
												<Row>
													<Text className='titulo-participacion'>
														Elige un Concurso
													</Text>
												</Row>
												<Row>
													<Text className='subtitulo-participacion mt-1'>
														Selecciona alguno de nuestros concursos disponibles
													</Text>
												</Row>
											</Col>
										</Row >
									</Col>
									<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
										<Row>
											<Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<Row align={"middle"} className="fondo-elipsis center">
													<img src='/img/bases.png' alt='boleto' />
												</Row>
											</Col>
											<Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
												<Row>
													<Text className='titulo-participacion'>
														Bases del Concurso
													</Text>
												</Row>
												<Row>
													<Text className='subtitulo-participacion mt-1'>
														Consulta y monitorea las bases para participar
													</Text>
												</Row>
											</Col>
										</Row >
									</Col>
									<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
										<Row>
											<Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<Row align={"middle"} className="fondo-elipsis center">
													<img src='/img/boleto.png' alt='boleto' />
												</Row>
											</Col>
											<Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
												<Row>
													<Text className='titulo-participacion'>
														Compra tus Boletos
													</Text>
												</Row>
												<Row>
													<Text className='subtitulo-participacion mt-1'>
														Sigue la dinámica y adquiere tu participación del concurso
													</Text>
												</Row>
											</Col>
										</Row >
									</Col>
									<Col sxs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
										<Row>
											<Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
												<Row align={"middle"} className="fondo-elipsis center">
													<CarroIcon />
												</Row>
											</Col>
											<Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
												<Row>
													<Text className='titulo-participacion'>
														¡GANA!
													</Text>
												</Row>
												<Row>
													<Text className='subtitulo-participacion mt-1'>
														Los ganadores serán anunciados en nuestras redes sociales
													</Text>
												</Row>
											</Col>
										</Row >
									</Col>
								</Row>
							</Col>
						</Row>
					</Content>
				</Row>

				<Divider style={{ backgroundColor: "#F26513" }} />

				<Row justify={"center"}>
					<Content>
						<Row justify={"center"}>
							<Text className='titulo2'>
								¡Adquiere tu participación ahora!
							</Text>
						</Row>
						<Row justify={"center"}>
							<div id='boletos' />
							<Content className='width-boletos'>
								<Row className='m-3'>
									<Col className='center width-100'>

										<Boletos sorteo_id={this.state?.sorteo?._id} total={this.state.sorteo.cantidad_boletos} costo={this.state.sorteo.costo_boleto} />

									</Col>

								</Row>
							</Content>

						</Row>

						{/* <Row justify={"center"} className="mb-1 width-100">
						<div className='boleto-container'>
							<img src='/img/boleto-info.png' alt='boleto' className='width-100' />
							<div className='titulo'>
								Concurso de Rifa
							</div>
							<div className='icono-ojo'>
								<img src='/img/ojo-icono.png' alt='ojo' />
							</div>
							<div className='rifa'>
								<Row>
									<Text className='info'>
										{this.state.sorteo?.nombre}
									</Text>
								</Row>
								<Row>
									<Text className='seccion'>
										RIFA O PROMOCIÓN
									</Text>
								</Row>

							</div>
							<div className='participante'>
								<Row>
									<Text className='info'>
										Jose de Jesus Parra
									</Text>
								</Row>
								<Row>
									<Text className='seccion'>
										NOMBRE DEL PARTICIPANTE
									</Text>
								</Row>

							</div>
							<div className='numero'>
								<Row>
									<Text className='info'>
										002153
									</Text>
								</Row>
								<Row>
									<Text className='seccion'>
										NÚMERO DE PARTICIPACIÓN
									</Text>
								</Row>

							</div>
							<div className='fecha'>
								<Row>
									<Text className='info'>
										{moment(this.state.sorteo?.fecha_finalizacion).format('LL')}
									</Text>
								</Row>
								<Row>
									<Text className='seccion'>
										FECHA DE FINALIZACIÓN
									</Text>
								</Row>

							</div>
							<div className='confirmacion'>
								<Row justify={"center"}>
									<Text className='info'>
										002153
									</Text>
								</Row>
								<Row justify={"center"}>
									<Text className='seccion'>
										Confirmación
									</Text>
								</Row>

							</div>
						</div>

					</Row> */}

						<Row justify={"center"} className="mb-1">
							<Space>
								<Button className='btn-boletos-green' onClick={() => { !this.props.guest && !this.props.user ? this.setState({ modalGuestVisible: true }) : this.setState({ toggleCarrito: !this.state.toggleCarrito }) }}>
									Comprar
								</Button>
								<Button className='boletos' onClick={() => { this.setState({ modalRuleta: true }) }}>

									¡RULETA DE LA SUERTE!

								</Button>
							</Space>

						</Row>
					</Content>
				</Row>


				<Divider style={{ backgroundColor: "#F26513" }} />
				<Row justify={"center"}>
					<Recientes />
				</Row>


				<Footer />

				<ModalGuest
					visible={this.state.modalGuestVisible}
					onSuccess={() => {
						message.success("¡Gracias!, Puede continuar con la compra.")
						this.setState({
							modalGuestVisible: false,
							toggleCarrito: !this.state.toggleCarrito
						})
					}}
					onClose={() => {
						this.setState({ modalGuestVisible: false })
					}}
				/>

				<ModalRuleta
					visible={this.state.modalRuleta}
					onClose={() => this.setState({ modalRuleta: false })}
					sorteo_id={this.state?.sorteo?._id}
					costo={this.state.sorteo.costo_boleto}
				/>

			</Layout>
		)
	}
}

export default function Vista() {
	let guest = useContext(Guest)
	let user = useContext(User)

	return <Landing params={useParams()} user={user} guest={guest} />
}