import { Button, Col, Layout, Row, Spin, Typography, message, Image } from 'antd'
import React, { Component } from 'react'

import { FaSquareFull } from 'react-icons/fa'


import axios from "axios"
import Slider from 'react-slick'
import Footer from './Footer'
import Header from './Header'

import '../../Styles/Public/landing.scss'

import { Link } from 'react-router-dom'
import { CarroIcon, ChevronLeft, ChevronRight, FlechasDecorativas } from '../Widgets/Iconos'
import Recientes from './Recientes'
import WidgetFacebook from './WidgetFacebook'

const { Content } = Layout
const { Text } = Typography


class Landing extends Component {

	// Slider methods
	next() {
		this.slider.slickNext()
	}

	previous() {
		this.slider.slickPrev()
	}

	constructor(props) {
		super(props)
		this.state = {
			ventas: 0,
			sorteos: 0,
			ganadores: 0,
			concluidos: 3,
			rifas: {
				data: []
			},
			slide: 0,
			width: 0,
			height: 0,
			currentIndex: 0,
		}
	}


	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		this.setState({ width: window.innerWidth, height: window.innerHeight })
		this.getConters()
		this.getRifas()
		this.getCarousels()
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}


	/**
	 * @static
	 * @memberof Landing
	 *
	 * @method coungetContersters
	 * @description Obtiene informacion de las ventas
	 * */
	getConters = () => {
		axios.get('/landing/counters')
			.then(response => {
				console.log("response", response.data);
				this.setState({
					ventas: response.data.ventas,
					sorteos: response.data.sorteos,
					ganadores: response.data.ganadores,
					concluidos: response.data.concluidos,
				})

			})
			.catch(error => {

			})

	}

	getRifas = () => {
		axios.get('/landing/sorteos')
			.then(({ data }) => {
				this.setState({
					rifas: data
				})

			})
			.catch(error => {
				message.error("Error al obtener los sorteos")
			})
	}

	getCarousels = () => {
		axios.get('/landing/carousels')
			.then(({ data }) => {
				console.log("carousels", data);
				if (Array.isArray(data.imagenes_celular)) data.imagenes_celular = data.imagenes_celular.map(file => axios.defaults.baseURL + '/landing/carousels/?imagenes=' + file.file + "&Authorization=" + sessionStorage.getItem("token"))//`${axios.defaults.baseURL}/info/${data._id}/banner`
				if (Array.isArray(data.imagenes_escritorio)) data.imagenes_escritorio = data.imagenes_escritorio.map(file => axios.defaults.baseURL + '/landing/carousels/?imagenes=' + file.file + "&Authorization=" + sessionStorage.getItem("token"))//`${axios.defaults.baseURL}/info/${data._id}/banner`

				this.setState({
					carousels: data
				})
			})
			.catch(error => {
				message.error("Error al obtener los carousels")
			})
	}

	updateDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight })
	}

	render() {

		const { rifas, slide, carousels } = this.state

		const sliderSettings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1
		}

		return (
			<Layout id={'landing'} className="transparent landing">
				<Header />
				<div id='inicio' />

				<Slider ref={c => (this.slider = c)} afterChange={index => this.setState({ slide: index })} className='slider-background' {...sliderSettings}>
					{((Array.isArray(this.carousels?.imagenes_escritorio) && this.state.carousels?.imagenes_escritorio.length > 0) || (Array.isArray(this.state.carousels?.imagenes_celular) && this.state.carousels?.imagenes_celular.length > 0)) ?

						this.state.carousels.imagenes_escritorio.map((e, index) => <div>
							{/* <Image src={e} alt='auto-premio' /> */}
							<Image src={this.state.width > 1200 ? e : this.state.carousels.imagenes_celular[index]} alt='auto-premio' preview={false} />
							{console.log(e)}
							
						</div>)
						:
						(Array.isArray(this.state.carousels?.imagenes) && this.state.carousels?.imagenes_escritorio.length > 0) ?

							this.state.carousels.imagenes.map((e, index) => <div>
								<Image src={e} alt='auto-premio' preview={false} />

							</div>)
							:

							rifas.data?.map(r => (
								<div key={'slide' + r._id}>
									<img src={`${axios.defaults.baseURL}/landing/sorteo/${r._id}?banner=true`} alt='auto-premio' />
								</div>
							))


					}
					{/* {
						rifas.data?.map(r => (
							<div key={'slide' + r._id}>
								<img src={`${axios.defaults.baseURL}/landing/sorteo/${r._id}?banner=true`} alt='auto-premio' />
							</div>
						))
					} */}
				</Slider>


				<Content className='slider-foreground'>
					<Row>
						<Col span={1}>
							<Row align={"middle"} justify={"center"} className='full-height'>
								<button className='slider-button' onClick={this.previous.bind(this)}>
									<ChevronLeft />
								</button>
							</Row>

						</Col>
						{
							rifas?.data?.length > 0 && (
								<Col span={22}>
									<Row className='full-height slider-content' align={"middle"}>
										<Col className=''>
											<Row>
												<FaSquareFull color='#40DDB6' className='mr-1' />
												<Text className='mensaje'>
													Tú podrías ser nuestro siguiente ganador
												</Text>
											</Row>
											<Row>
												<Text className='titulo'>
												{carousels?.descripciones[slide]}
												</Text>
											</Row>
											<Row>
												{/* <Text className='subtitulo mt-2 mb-2'>
													{carousels?.descripciones[slide]}
												</Text> */}
											</Row>
											<Row>
												{/* <Link to={`/evento/${rifas?.data[slide]?.url}`}>
													<Button className='boletos' size='large'>
														Comprar Boletos
													</Button>
												</Link> */}
											</Row>
										</Col>
									</Row>
								</Col>
							)
						}
						{
							rifas?.data?.length < 1 && (
								<Col span={22}>
									<Row className='full-height pd-1' align={"middle"} justify={"center"}>
										<Spin />
									</Row>
								</Col>
							)
						}
						<Col span={1}>
							<Row align={"middle"} justify={"center"} className='full-height'>
								<button className='slider-button' onClick={this.next.bind(this)}>
									<ChevronRight />
								</button>
							</Row>

						</Col>
					</Row>
				</Content>


				<div id="auto1">
					<Row className='width-100'>
						<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
							<img src='/img/carros.png' style={{ zIndex: '-2', maxWidth: "100vw" }} />
						</Col>
						<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className="pt-4 mt-3 p-1">
							<Row>
								<FaSquareFull color='#40DDB6' className='mr-1' />
								<Text className='mensaje2'>
									Concursa y Gana
								</Text>
							</Row>
							<Row>
								<Text className='titulo2'>
									¡Un nuevo&nbsp;
								</Text>
								<Text className='titulo2-alt'>
									auto&nbsp;
								</Text>
								<Text className='titulo2'>
									podría estar
								</Text>
							</Row>
							<Row>
								<Text className='titulo2'>
									esperándote!
								</Text>
							</Row>

							<Row>
								<Text className='subtitulo2 mt-2 mb-2'>
									Sigue las bases de nuestros concursos y estrena un automóvil nuevo este 2023.
								</Text>

							</Row>

							<Row align={"middle"} justify={"space-between"} className="mt-1">
								<Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
									<Row justify={"start"}>
										<Button className='boletos mr-2' size='large'>
											¡Conoce nuestros concursos!
										</Button>
									</Row>

								</Col >
								<Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
									<Row justify={"end"}>
										<FlechasDecorativas />
									</Row>

								</Col>


							</Row>
						</Col>
					</Row>
				</div>

				<div id='dinamica' style={{ height: "50px" }}>
				</div>

				<div className='divider'>
					<Row justify={"center"}>
						<Text className='divider-text'>
							Conoce nuestras dinámicas para concursar y ganar
						</Text>
					</Row>
				</div>

				<Row justify={"center"}>
					<Content className='mt-1'>
						<Row justify={"center"}>
							<Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10} className='center'>
								<img src='/img/producto.png' className='producto-img' style={{ maxWidth: "100vw" }} />
							</Col>
							<Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14} className='p-1'>
								<Row>
									<Text className='titulo2'>
										¿Cómo puedo&nbsp;
									</Text>
									<Text className='titulo2-alt'>
										participar?
									</Text>
								</Row>
								<Row className='mt-3' gutter={[16, 50]}>
									<Col span={12}>
										<Row>
											<Col xs={24} sm={24} md={8} lg={24} xl={8} xxl={8}>
												<Row align={"middle"} className="fondo-elipsis center">
													<img src="/img/trofeo.png" alt="" />
												</Row>
											</Col>
											<Col xs={24} sm={24} md={16} lg={24} xl={16} xxl={16}>
												<Row>
													<Text className='titulo-participacion'>
														Elige un Concurso
													</Text>
												</Row>
												<Row>
													<Text className='subtitulo-participacion mt-1'>
														Selecciona alguno de nuestros concursos disponibles
													</Text>
												</Row>
											</Col>
										</Row >
									</Col>
									<Col span={12}>
										<Row>
											<Col xs={24} sm={24} md={8} lg={24} xl={8} xxl={8}>
												<Row align={"middle"} className="fondo-elipsis center">
													<img src='/img/bases.png' alt='boleto' />
												</Row>
											</Col>
											<Col xs={24} sm={24} md={16} lg={24} xl={16} xxl={16}>
												<Row>
													<Text className='titulo-participacion'>
														Bases del Concurso
													</Text>
												</Row>
												<Row>
													<Text className='subtitulo-participacion mt-1'>
														Consulta y monitorea las bases para participar
													</Text>
												</Row>
											</Col>
										</Row >
									</Col>
									<Col span={12}>
										<Row>
											<Col xs={24} sm={24} md={8} lg={24} xl={8} xxl={8}>
												<Row align={"middle"} className="fondo-elipsis center">
													<img src='/img/boleto.png' alt='boleto' />
												</Row>
											</Col>
											<Col sxs={24} sm={24} md={16} lg={24} xl={16} xxl={16}>
												<Row>
													<Text className='titulo-participacion'>
														Compra tus Boletos
													</Text>
												</Row>
												<Row>
													<Text className='subtitulo-participacion mt-1'>
														Sigue la dinámica y adquiere tu participación del concurso
													</Text>
												</Row>
											</Col>
										</Row >
									</Col>
									<Col span={12}>
										<Row>
											<Col xs={24} sm={24} md={8} lg={24} xl={8} xxl={8}>
												<Row align={"middle"} className="fondo-elipsis center">
													<CarroIcon />
												</Row>
											</Col>
											<Col sxs={24} sm={24} md={16} lg={24} xl={16} xxl={16}>
												<Row>
													<Text className='titulo-participacion'>
														¡GANA!
													</Text>
												</Row>
												<Row>
													<Text className='subtitulo-participacion mt-1'>
														Los ganadores serán anunciados en nuestras redes sociales
													</Text>
												</Row>
											</Col>
										</Row >
									</Col>
								</Row>
							</Col>
						</Row>
					</Content>
				</Row>

				<div id='ganadores' style={{ height: "50px" }}>
				</div>
				{/* <div className='divider pt-3 pd-3 pb-3' >
					<Row justify={"center"}>
						<Content>
							<Row>
								<Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
									<Row align={"middle"} className="mb-2">
										<FaSquareFull color='#40DDB6' className='mr-1' />
										<Text className='testimonios-mensaje'>
											Testimonios
										</Text>
									</Row>
									<Row>
										<Text className='testimonios-titulo'>
											Nuestros
										</Text>
									</Row>
									<Row>
										<Text className='testimonios-titulo'>
											Ganadores
										</Text>
									</Row>
									<Row className='mt-1'>
										<Text className='testimonios-titulo-pequeno'>
											Lo que nuestros clientes y participantes dicen de nosotros
										</Text>
									</Row>
								</Col>
								<Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
									<Row className='ml-2'>
										<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className=" pd-3">
											<Row>
												<Col>
													<Avatar src='/img/persona-1.jpg' size={100} />
												</Col>
												<Col className='ml-1'>
													<Row>
														<Text className='testimonios-nombre'>
															Juan Perez
														</Text>
													</Row>
													<Row>
														<Text className='testimonios-tipo'>
															Ganador
														</Text>
													</Row>
													<Row className='mt-1'>
														<Rating allowFraction={true} initialValue={5} readonly fillIcon={<BsStarFill size={25} />} emptyIcon={<BsStar size={25} />} />
													</Row>
												</Col>

											</Row>
											<Row className='mt-2'>
												<Text className='testimonios-opinion'>
													“ dd an excerpt from your personal biography, or simply let the world know who you are and what you have to offer. Connect with your site visitor’s on a personal level and make sure that your site.
												</Text>
											</Row>
										</Col>
										<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className=" pd-3">
											<Row>
												<Col>
													<Avatar src='/img/persona-2.jpg' size={100} />
												</Col>
												<Col className='ml-1'>
													<Row>
														<Text className='testimonios-nombre'>
															Victor Dominguez
														</Text>
													</Row>
													<Row>
														<Text className='testimonios-tipo'>
															Participante
														</Text>
													</Row>
													<Row className='mt-1'>
														<Rating allowFraction={true} initialValue={4.5} readonly fillIcon={<BsStarFill size={25} />} emptyIcon={<BsStar size={25} />} />
													</Row>
												</Col>

											</Row>
											<Row className='mt-2'>
												<Text className='testimonios-opinion'>
													“ Leverage agile frameworks to provide a robust synopsis for high level overviews ”
												</Text>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Content>
					</Row>
				</div> */}
				<div className='divider'>
					<Row justify={"center"} className="width-100 row-ribbon-counters">
						<Col xs={24} md={12} lg={4} className="flex-column">
							<Text className="title">Ventas Realizadas</Text>
							<Text className="info">{this.state.ventas?.toMoney(true, false)}</Text>
						</Col>
						<Col xs={24} md={12} lg={4} className="flex-column">
							<Text className="title">Sorteos</Text>
							<Text className="info">{this.state.sorteos?.toMoney(true, false)}</Text>
						</Col>
						<Col xs={24} md={12} lg={4} className="flex-column">
							<Text className="title">Concursos Realizados</Text>
							<Text className="info">{this.state.concluidos?.toMoney(true, false)}</Text>
						</Col>
					</Row>

				</div>

				<div id='concursos' style={{ height: "100px" }}>
				</div>


				<Row justify={"center"} gutter={[16, 16]}>
					<Col>
						<Recientes />
					</Col>
					{/* <Col xl={4}>
					</Col> */}
				</Row>
				<Footer />

			</Layout>
		)
	}
}

export default function () {

	return <Landing />
}