import React from 'react';
import ReactEcharts from "echarts-for-react";
import axios from "axios"
import { Card, Col, Row, Typography, List, Button, Progress, Space, Tooltip} from 'antd';

import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { IconLoss, IconMenuDots, IconProfit} from './Iconos'
import { ButtonDelete, ButtonView, ButtonEdit, ButtonImg, } from "./Buttons"
import { EstatusRifa, EstatusCliente } from '../Utils';
import Avatar from 'antd/lib/avatar/avatar';

import '../../Styles/Global/cards.css';

import moment from 'moment';
import 'moment/locale/es';




const { Text, Paragraph, Title } = Typography;





/**
 * CardSaldosGraph
 * 
 * @param {*}
 * @description Tarjeta de Saldos con Grafica para Dashboard
 */
const CardSaldosGraph = ({ title = "Saldos", info = null }) => {

	return <Card 
		className="card-shadow card-saldos-dashboard" 
		bordered={false} 
		title={title}
	>
		<Row>
			<Col span={24}>
				<Text>{info}</Text>
			</Col>
		</Row>
	</Card>
}

/**
 * CardMontoVentaMensual
 * 
 * @param {*}
 * @description Tarjeta de Venta Mensual con Lista Top 5 Clientes para Dashboard
 */
const CardMontoVentaMensual = ({ title = '', icon = 'down', color = '#006BFF' }) => {
	let data = [
		{ color: '#4807EA', nombre: 'Rubro', monto: 0 },
		{ color: '#FF9C54', nombre: 'Rubro', monto: 0 },
		{ color: '#00FF47', nombre: 'Rubro', monto: 0 },
		{ color: '#00FF47', nombre: 'Rubro', monto: 0 },
		{ color: '#00FF47', nombre: 'Rubro', monto: 0 }
	];

	return <Card bordered={false} className='card-venta-mensual-dashboard' title="Monto Venta Mensual">
		<Card.Meta
			title={<> $32,134 <span className={icon === 'up' ? 'ventas-up' : 'ventas-down'}>{icon === 'up' ? <ArrowUpOutlined /> : <ArrowDownOutlined />} 2.5 %</span></>}
			description='Comparado con $20,500 presupuestado'
		/>
		<List
			size='small'
			className='list-border-none list-top-dashboard'
			header={<div className='top-list-header'>Top 5 Clientes</div>}
			bordered={false}
			dataSource={data}
			renderItem={item => <List.Item>
				<List.Item.Meta
					avatar={<Avatar shape="square" size="small" style={{ backgroundColor: item.color }} />}
					title={item.nombre}
				/>

				<div className="list-top-dashboard-monto">${item.monto}</div>
			</List.Item>}
		/>
	</Card>
}

/**
 * CardMontoVentaAnual
 * 
 * @param {*}
 * @description Tarjeta de Venta Anual con Lista Top 5 Clientes para Dashboard
 */
const CardMontoVentaAnual = ({ title = '', icon = 'down', color = '#006BFF' }) => {
	let data = [
		{ color: '#4807EA', nombre: 'Rubro', monto: 0 },
		{ color: '#FF9C54', nombre: 'Rubro', monto: 0 },
		{ color: '#00FF47', nombre: 'Rubro', monto: 0 },
		{ color: '#00FF47', nombre: 'Rubro', monto: 0 },
		{ color: '#00FF47', nombre: 'Rubro', monto: 0 }
	];

	return <Card bordered={false} className='card-venta-mensual-dashboard' title="Monto Venta Anual">
		<Card.Meta
			title={<> $32,134 <span className={icon === 'up' ? 'ventas-up' : 'ventas-down'}>{icon === 'up' ? <ArrowUpOutlined /> : <ArrowDownOutlined />} 2.5 %</span></>}
			description='Comparado con $1,000,000 presupuestado'
		/>
		<List
			size='small'
			className='list-border-none list-top-dashboard'
			header={<div className='top-list-header'>Top 5 Clientes</div>}
			bordered={false}
			dataSource={data}
			renderItem={item => <List.Item>
				<List.Item.Meta
					avatar={<Avatar shape="square" size="small" style={{ backgroundColor: item.color }} />}
					title={item.nombre}
				/>

				<div className="list-top-dashboard-monto">${item.monto}</div>
			</List.Item>}
		/>
	</Card>
}

/**
 * CardDashboardGraph
 * 
 * @param {*}
 * @description Grafica 'Multiple X Axes' para Dashboard
 */
const CardDashboardGraph = ({data = [], emptyText = "Sin data", clientes = false}) => {

	return <Card 
		className='card-shadow card-graph-dashboard' 
		bordered={false}
	>
		{
			data.map(item => {

				if(clientes){
					return <Row className="width-100 mb-1">

                        <Col xs={24} md={10} className="center">
                            <Text ellipsis strong>{item.nombre} {item.apellido_paterno} {item.apellido_materno ?? ""}</Text>
                        </Col>
                        <Col xs={24} md={10} className="center">
                            <Text ellipsis>{item.email ?? "-"}</Text>
                        </Col>
                        <Col xs={24} md={4} className="center">
                            {EstatusCliente(item.estatus)}
                        </Col>
                    </Row>
				}

				return <Row className="width-100 mb-1" gutter={[8,8]}>
                    <Col xs={24} md={8} className="center">
                        <Text strong>{item?.cliente_id?.nombre ? <>
						{item?.cliente_id?.apellido_paterno} {item?.cliente_id?.apellido_materno}
						</> : <>
						{item?.datos_cliente?.nombre ? <>
							{item?.datos_cliente?.nombre} {item?.datos_cliente?.apellido_paterno}
						</> : <>N/A</>}
						</>}</Text>
                    </Col>

                    <Col xs={24} md={6} className="center">
                        <Text className="text-gray">{item?.monto?.toMoney(true)} USD</Text>
                    </Col>
                    <Col xs={24} md={10} className="center">
                        <Text className="text-gray">{moment(item.fecha).format('LLLL')}</Text>
                    </Col>
                </Row>
			})
		}
		{
			data.length === 0 ? <Row>
				<Col span={24} className ="center">
					<Text strong className="text-gray">{emptyText}</Text>
				</Col>
			</Row> : null 
		}
	</Card>
}



/**
 * @const CardSaldo
 * @description Card que muetra el total de Saldo diponible entre todas las cuentas
 * @param num saldo suma de los saldos disponibles
 * @param num cuentas cantidad de cuentas registradas
 */
const CardSaldo = ({ saldo = 0, cuentas = 0 }) => {
	return <Card className="card-shadow card-saldo">
		<Card.Meta
			className="card-saldo-main-meta"
			title="Saldo al Momento"
			description={<><Text style={{ marginRight: '0.5rem' }}>$ {saldo?.toMoney(true)}</Text><small>USD</small></>} />
		<Card.Meta
			className="card-saldo-sub-meta"
			title="Cuentas Incluidas"
			description={<>{cuentas}</>} />
	</Card>
}


/**
 * @const CardProfit
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
const CardGains = ({ title = "Profit", status = 'success' }) => {
	return <Card className="card-shadow  card-gains card-border-16">
		<Row align='middle' justify='center'>
			<Col span={4} lg={4}>
				<div className={`${title === 'Profit' ? 'green-box' : 'red-box'}`}>
					{title === 'Profit' ? <IconProfit /> : <IconLoss />}
				</div>
			</Col>
			<Col span={16} lg={16} >
				<Text className="card-title center">{title}</Text>
			</Col>
			<Col span={4} lg={4} className=" dots">
				<Button ghost icon={<IconMenuDots />}></Button>
			</Col>
		</Row>
		<Row gutter={[8, 8]}>
			<Col span={24} className="center">
				<Text>{moment().format('DD/MM/YYYY')}</Text>
			</Col>
			<Col span={16} className="flex-left">
				<Text className="text-paid">Monto Pagado</Text>
			</Col>
			<Col span={8} className="flex-right">
				<Text className="text-paid">Total</Text>
			</Col>
			<Col span={24} className="center">
				<Progress percent={90} status={status} showInfo={false} />
			</Col>
		</Row>
		<Row>
			<Col span={24} className="center">
				<Text>{moment().format('DD/MM/YYYY')}</Text>
			</Col>
			<Col span={16} className="flex-left">
				<Text className="text-paid">Monto Pagado</Text>
			</Col>
			<Col span={8} className="flex-right">
				<Text className="text-paid">Total</Text>
			</Col>
			<Col span={24} className="center">
				<Progress percent={100} status={status} showInfo={false} />
			</Col>

		</Row>

	</Card>
}

/**
 * 
 * 
 * @export
 * @const VentasAnuales
 * @description Card de ventas por año del dashboard de CRM
 */
const CardVentasAnuales = (props) => {

	return <Card title={"Ventas por año"} bordered={false} className="card-shadow echart-card card-crm-ventas-anuales">
		<ReactEcharts
			style={{ height: '240px', width: "100%" }}
			option={{
				responsive: true,
				maintainAspectRatio: false,
				xAxis: {
					type: 'category',
					data: props.ventas_anuales?.map(y => y.año.toString())
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						data: props.ventas_anuales?.map(y => y.monto.toString()),
						type: 'bar',
						showBackground: true,
						backgroundStyle: {
							color: 'rgba(180, 180, 180, 0.2)'
						}
					}
				]
			}}
		/>
	</Card>
}


/**
 * 
 * 
 * @export
 * @const CardRifa
 * @description Card para representar a una rifa
 */
const CardRifa = (props) => {

	let ruta = "/img/no-image.jpg"
	if(props?.banner) ruta = `${axios.defaults.baseURL}/sorteo/${props._id}?banner=true&Authorization=${sessionStorage.getItem("token")}`

	return <Card
		hoverable
		cover={
			<div className="card-rifa card-image" style={{ backgroundImage: `url("${ruta}")`}}>
			</div>
		}
		className="card-rifa"
	>
		<Row>
			<Col span={17} className="">
				<Tooltip placement="topLeft" title={props.nombre}>
					<Title level={3} className="title" ellipsis>{props.nombre}</Title>
				</Tooltip>
			</Col>
			<Col span={7} className="flex-right">
				{EstatusRifa(props.estatus)}
			</Col>
			<Col span={24}>
				<Paragraph className="descripcion"> {props.descripcion} </Paragraph>
			</Col>
			<Col span={24} className="center mb-1">
				<Text className="fecha"> {moment(props.fecha_inicio).format("dddd D [de] MMMM [del] YYYY")} - {moment(props.fecha_finalizacion).format("dddd D [de] MMMM [del] YYYY")}</Text>
			</Col>
			<Col span={24} className="center">
				<Space>
					<ButtonView
						onClick={props?.onView}
					/>
					{/* <ButtonImg
						onClick={props?.onImg}
					/> */}
					<ButtonEdit
						onClick={props?.onEdit}
					/>
					<ButtonDelete
						onConfirm={props?.onDelete}
					/>
				</Space>
			</Col>
		</Row>
	</Card>

}


/**
 * @export
 * @const CardPremio
 * @description Card para representar un premio
 */
const CardPremio = (props) => {

	let ruta = "/img/no-image.jpg"
	if(props?.imagenes?.length > 0) ruta = `${axios.defaults.baseURL}/premio/${props._id}?imagen=${props.imagenes[0].file}&Authorization=${sessionStorage.getItem("token")}`
	if(props?.imagenes?.length > 0 && props.local === true && props.imagenes[0] instanceof File) ruta = URL.createObjectURL(props.imagenes[0])

	return <Card
		hoverable
		cover={
			<div className="card-rifa card-image"  style={{ backgroundImage: `url("${ruta}")`}}>
			</div>
		}
		className="card-rifa"
	>
		{props.index >= 0 ? <div className="div-index">{props.index + 1}</div> : null}
		<Row>
			<Col md={24} className="center">
				<Title level={3} className="title" ellipsis>{props.nombre}</Title>
			</Col>
			<Col span={24}>
				<Paragraph className="descripcion"> {props.descripcion} </Paragraph>
			</Col>
			<Col span={24} className="center">
				<Text className="fecha"> $ {props.costo.toMoney(true)} USD</Text>
			</Col>
			<Col span={24} className="center">
				<Space>
					<ButtonEdit
						onClick={() => props.index >= 0 ? props.onEdit(props) : props?.onEdit()}
					/>
					<ButtonDelete
						onConfirm={() => props.index >= 0 ? props.onDelete(props.index) : props?.onDelete()}
					/>
				</Space>
			</Col>
		</Row>
	</Card>

}

const MemoizedMyComponent = React.memo(CardPremio);


const CardPaso = (props) => {

	let ruta = "/img/recientes-1.png"
	if(props?.imagen?.name) ruta = `${axios.defaults.baseURL}/sorteo/${props.sorteo_id}?imagen=${props.imagen?.file}&Authorization=${sessionStorage.getItem("token")}`
	if(props?.imagen instanceof File && props.local === true) ruta = URL.createObjectURL(props.imagen)

	return <Card	
	>
		<Row>
			<Col xs={6} md={4} className="center">
				<div className="card-image-sm"  style={{ backgroundImage: `url("${ruta}")`}}>
				</div>
			</Col>
			<Col xs={14} md={16}>
				<Row>
					<Col span={24}>
						<Text ellipsis>{props.titulo}</Text>
					</Col>
					<Col span={24}>
						{props.descripcion}
					</Col>
				</Row>
			</Col>
			<Col xs={4} md={4} className="center">
				<Space>
					<ButtonEdit
						onClick={() => props.index >= 0 ? props.onEdit(props) : props?.onEdit()}
					/>
					<ButtonDelete
						onConfirm={() => props.index >= 0 ? props.onDelete(props.index) : props?.onDelete()}
					/>
				</Space>
			</Col>
		</Row>
	</Card>

}

const MemoizedCardPaso = React.memo(CardPaso);

export {
	CardSaldosGraph,
	CardMontoVentaMensual,
	CardMontoVentaAnual,
	CardDashboardGraph,
	CardSaldo,
	CardGains,
	CardVentasAnuales,
	CardRifa,
	MemoizedMyComponent as CardPremio,
	MemoizedCardPaso as CardPaso,
	
}