import React, { useContext, useState } from 'react';
import { Space, Dropdown, Menu, Col, Row, Layout, Input, Typography, Button } from 'antd';
import { useNavigate, Link } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';


//componentes
import { User, SetUser } from '../../Hooks/Logged'
import Avatar from "../Widgets/Avatar/Avatar"

import '../../Styles/Global/header.scss'

const { Header } = Layout;
const { Search } = Input;
const { Text } = Typography;


/**
 * @const Navbar
 * @description Header del sistema
 * @param {*} {  showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal }
 */
const Navbar = ({ showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal, admin }) => {


	let user = useContext(User)
	let setUser = React.useContext(SetUser)
	const navigate = useNavigate()


	/**
	 * @const cerrarSesion
	 * @description Cierra la sesion
	 */
	const cerrarSesion = () => {
		setUser(undefined);
		sessionStorage.clear();
		navigate("/")

		if(window.FB){
        	window.FB.logout(function(response) {
        		console.log("response", response);
			});
        }

	};

	/**
	* @const UserMenu
	* @description Muestra el Menu del dashboard
	*/
	const UserMenu = () => {
		return (
			<Col flex="auto" className="header-content" style={{ display: "flex", flex: 1, columnGap: '1em', padding: "0 1em" }} >
				<Search
					placeholder="Buscar"
					onSearch={(e) => { setFilterSearch(e) }}
					id="inputFilterSearch"
					enterButton
					size='large'
					className="input-global-search"
				/>
				<Space size={"large"}>
					
					<Dropdown
						className="dropdown-menu"
						arrow={true}
						overlay={
							<Menu>
								<Menu.Item key="0" onClick={cerrarSesion}>
									Cerrar Sesión
								</Menu.Item>
							</Menu>
						} trigger={['click']}>
						<Space>
							<Text>{user?.nombre} {user?.apellidos}</Text> <DownOutlined />
						</Space>
					</Dropdown>
				</Space>
			</Col>
		)
	}

	return (
		<Header>
            <Row className='w-100' align={'middle'} gutter={[16, 16]} justify={"space-between"}>
                <Col xs={14} md={12} lg={8} xl={6} xxl={4}>
                    <img className="w-100" src="/img/logo.svg" alt="Fuga pa´ Durango" />
                </Col>
                <Col xs={10} md={12} lg={6} xl={6}>
                    <Row justify={"end"}>
                        <Dropdown
							overlay={
								<Menu>
									<Menu.Item key="0" onClick={cerrarSesion}>
										Cerrar Sesión
									</Menu.Item>
								</Menu>
							} trigger={['click']}>
							<Button
								className="btn-menu-header-customer"
							>
                            	<Avatar
                            		name={`${user?.nombre ?? ""} ${user.apellidos ?? ""}`}
                            	/> <Text>{user?.nombre}</Text> <DownOutlined />
                            </Button>
						</Dropdown>
                    </Row>
                </Col>
            </Row>
        </Header>
	);

}

export default Navbar;