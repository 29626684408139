import React, { Component } from 'react'
import { Row, Col, Form, Input, Button, Typography, Modal, Spin } from 'antd';
import '../../Styles/Global/auth.scss';

import axios from 'axios';

const { Title, Text } = Typography

/**
 * 
 * @class Recovery
 * @extends {Component}
 * @description Componente Recovery para recuperar contraseñas
 */
class Recovery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		}
	}

	handleSubmit = (values) => {
		this.setState({loading: true})
        axios.put("/password/recovery", { email: values.email })
            .then(res => {
                Modal.success({
                    title: 'Restablecer contraseña.',
                    content: '¡El correo ha sido enviado!'
                })
            })
            .catch(res => {
                console.log(res)
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no se ha podido enviar. Verifica que el correo sea correcto'
                });
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })
	}

	componentDidMount(){
		console.log("this.props", this.props);
	}

	render() {
		return(
			<Row className='layout-login'>
				<Col span={10} className="col-welcome">
					<Row>
						<div>
							<img width="400" src="/img/logo.svg" alt="José Hermosillo" />
							<p className='subtitle-isyt-erp'>Raffle Manager</p>
						</div>
					</Row>
				</Col>
				<Col span={14}>
					<Spin spinning={this.state.loading}>
						<div className='login-form-container'>
							<Form onFinish={this.handleSubmit} layout={"vertical"} requiredMark={false} className='login-form'>
								<Title level={3} className="h3-title">Restablecimiento de contraseña</Title>
								<div className="mb-3">Ingrese su correo electrónico y le enviaremos un enlace de reinicio</div>
								<Form.Item className='input-name' name="email" label="Correo electrónico"
										rules={[
												{ required: true, message: "Ingrese su correo" }
										]}>
										<Input placeholder="ejemplo@gmail.com" size="large"/>
								</Form.Item>
								<Form.Item>
										<Button 
											
											type="primary" 
											htmlType="submit" block size="large">Enviame el enlace</Button>
								</Form.Item>
							</Form>
						</div>
					</Spin>
				</Col>
			</Row>
		)
	}
}

export default function View(props) {

	return <Recovery {...props} />
}