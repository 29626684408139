import React, { useEffect, useState } from 'react'
import { PageHeader, Typography, Row, Col, Form, Image, Divider, Input, Button, message } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import axios from 'axios'
import { UploadOutlined } from '@ant-design/icons'
import { AiOutlineMinusCircle } from 'react-icons/ai'

import { Uploader } from '../Widgets/Uploaders'

const { Title, Text } = Typography

function ImagePreviewer({ src }) {
    const [visible, setVisible] = useState(false);
    const [scaleStep, setScaleStep] = useState(0.5);

    useEffect(() => {

        if (src)
            setVisible(true)
        else
            setVisible(false)

    }, [src])

    return <Image
        style={{ display: 'none' }}
        src={src}
        preview={{
            visible,
            src, //: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            onVisibleChange: (value) => {
                setVisible(value);
            },
        }}
    />
};

export default class Carousel extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            roles: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
            premios: [],
            pasos: [],
            imagePreviewer: null
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getCarousel()
    }

    formRef = React.createRef();

    getCarousel = () => {
        this.setState({ loading: true })
        axios.get('/carousel')
            .then(({ data }) => {
                console.log("data", data);
                this.formRef.current.setFieldsValue({
                    ...data,


                    imagenes_celular: Array.isArray(data.imagenes_celular) ? data.imagenes_celular.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/carousel/?imagen=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : [],

                    imagenes_escritorio: Array.isArray(data.imagenes_escritorio) ? data.imagenes_escritorio.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/carousel/?imagen=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : [],

                })


            })
            .catch(error => {
                console.log("error", error);
                message.error(error?.response?.data?.message ?? "Error al obtener la información")
            }).finally(() => this.setState({ loading: false }))
    }

    handleWarning = async (values) => {

        console.log(await this.formRef.current.validateFields())
        this.formRef.current.validateFields()
        this.onFinish(values)

    }

    onFinish = (values) => {

        try {


            console.log('values onFinish', values);
            // return 


            const formData = new FormData()
            formData.appendMultiple({
                descripciones: JSON.stringify(values.descripciones),
                imagenes_celular: undefined,
                imagenes_escritorio: undefined
            })

            if (values.imagenes_escritorio) {
                for (const imagen of values?.imagenes_escritorio) {
                    console.log("imagen", imagen);
                    if (imagen.originFileObj)
                        formData.append("imagenes_escritorio", imagen.originFileObj, imagen.name)
                    else
                        formData.append("imagenes_escritorio", imagen)
                }
            }

            if (values.imagenes_celular) {
                for (const imagen of values?.imagenes_celular) {
                    console.log("imagenes_celular", imagen);
                    if (imagen.originFileObj)
                        formData.append("imagenes_celular", imagen.originFileObj, imagen.name)
                    else
                        formData.append("imagenes_celular", imagen)
                }
            }



            this.updateCarousel(formData)


        } catch (e) {
            console.log("Error al realizar submit", e)
        }
    }

    updateCarousel = (values) => {
        this.setState({ loading: true })
        axios.post('/carousel', values)
            .then(response => {
                message.success("Actualizado con exito")
                this.getCarousel()
            })
            .catch(error => {
                console.log("error", error);
                message.error(error?.response?.data?.message ?? "Error al actualizar")
            }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <>
                <PageHeader
                    className='custom-page-header'
                    title={<Row>
                        <Col xs={20}>
                            <Title level={3}>Carrousel</Title>
                        </Col>
                    </Row>}
                />
                <Content className='admin-content content-bg pd-1'>
                    <Form
                        layout={"vertical"}
                        ref={this.formRef}
                        onFinish={this.handleWarning}
                        name="my-form"
                        className="mb-3"
                    >
                        <Row justify="center" gutter={[16, 4]}>
                            <Col xs={24} md={20}>
                                <Form.Item
                                    label="Imagenes Escritorio"
                                    name="imagenes_escritorio"
                                    valuePropName="fileList"
                                    getValueFromEvent={e => {
                                        if (Array.isArray(e)) {
                                            return e;
                                        }
                                        return e?.fileList;
                                    }}
                                    rules={[{
                                        validator: (_, value) => {
                                            console.log("value", value);
                                            console.log("other value", this.formRef.current.getFieldValue("imagenes_celular").length);

                                            if (value.length !== this.formRef.current.getFieldValue("imagenes_celular").length) {
                                                return Promise.reject("Las secciones deben de tener la misma cantidad de imagenes");
                                            }

                                            return Promise.resolve();
                                        }
                                    }]}
                                >
                                    <Uploader
                                        {...{
                                            method: "POST",
                                            name: "imagenes_escritorio",
                                            headers: {
                                                authorization: sessionStorage.getItem("token")
                                            },
                                            action: axios.defaults.baseURL + '/transacciones',
                                            onRemove: e => {

                                                console.log('e', e);
                                                axios.post('/carousel', {
                                                    
                                                    imagenes_escritorio: e.uid,
                                                })
                                            }
                                        }}
                                        listType="picture-card"
                                        beforeUpload={e => {
                                            console.log('e', e)
                                            return false
                                        }}
                                        previewFile={async (file, ...args) => {

                                            console.log('e', file, args);
                                            // return file;
                                            return new Promise((resolve, reject) => {

                                                var reader = new FileReader()

                                                reader.readAsDataURL(file)

                                                reader.onload = function () {
                                                    console.log(reader)
                                                    resolve(encodeURI(reader.result))
                                                }
                                                reader.onerror = function (error) {
                                                    reject(error)
                                                    console.log('Error: ', error)
                                                };
                                            })
                                        }}
                                        onPreview={(file) => this.setState({ imagePreview: encodeURI(file.thumbUrl) })}
                                    >
                                        <div>
                                            <UploadOutlined />
                                            <br />
                                            Subir Imagenes
                                        </div>
                                        <ImagePreviewer
                                            src={this.state.imagePreview}

                                        />
                                    </Uploader>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={20}>
                                <Form.Item
                                    label="Imagenes Celular"
                                    name="imagenes_celular"
                                    valuePropName="fileList"
                                    getValueFromEvent={e => {
                                        if (Array.isArray(e)) {
                                            return e;
                                        }
                                        return e?.fileList;
                                    }}
                                    rules={[{
                                        validator: (_, value) => {
                                            console.log("value", value);
                                            console.log("other value", this.formRef.current.getFieldValue("imagenes_escritorio").length);

                                            if (value.length !== this.formRef.current.getFieldValue("imagenes_escritorio").length) {
                                                return Promise.reject("Las secciones deben de tener la misma cantidad de imagenes");
                                            }

                                            return Promise.resolve();
                                        }
                                    }]}
                                >
                                    <Uploader
                                        {...{
                                            method: "POST",
                                            name: "imagenes_celular",
                                            headers: {
                                                authorization: sessionStorage.getItem("token")
                                            },
                                            action: axios.defaults.baseURL + '/transacciones',
                                            onRemove: e => {

                                                console.log('e', e);
                                                axios.post('/carousel', {
                                                    
                                                    imagenes_celular: e.uid,
                                                })
                                            }
                                        }}
                                        listType="picture-card"
                                        beforeUpload={e => {
                                            console.log('e', e)
                                            return false
                                        }}
                                        previewFile={async (file, ...args) => {

                                            console.log('e', file, args);
                                            // return file;
                                            return new Promise((resolve, reject) => {

                                                var reader = new FileReader()

                                                reader.readAsDataURL(file)

                                                reader.onload = function () {
                                                    console.log(reader)
                                                    resolve(encodeURI(reader.result))
                                                }
                                                reader.onerror = function (error) {
                                                    reject(error)
                                                    console.log('Error: ', error)
                                                };
                                            })
                                        }}
                                        onPreview={(file) => this.setState({ imagePreview: encodeURI(file.thumbUrl) })}
                                    >
                                        <div>
                                            <UploadOutlined />
                                            <br />
                                            Subir Imagenes
                                        </div>
                                        <ImagePreviewer
                                            src={this.state.imagePreview}

                                        />
                                    </Uploader>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={20}>
                                <div className="mb-2">
                                    <Divider style={{ marginBottom: "10px" }} >Descripciones</Divider>
                                    <Text italic className="mb-2"><small>Debe de haber la misma cantidad de descripciones que imagenes, si se deja el campo vacio se utlilizará la descripción predeterminada para el evento, las descripciones se asignaran en el mismo orden de las imagenes que estan sobre esta sección</small></Text>

                                </div>
                                <Form.List
                                    name="descripciones"
                                    label="Descripciones"
                                    rules={[{
                                        validator: (_, value) => {
                                            console.log("value", value);
                                            console.log("other value", this.formRef.current.getFieldValue("imagenes_escritorio").length);

                                            if (value.length !== this.formRef.current.getFieldValue("imagenes_escritorio").length || value.length !== this.formRef.current.getFieldValue("imagenes_celular").length) {
                                                return Promise.reject("Debe de existir la misma cantidad de descripciones que imagenes");
                                            }

                                            return Promise.resolve();
                                        }
                                    }]}
                                >

                                    {
                                        (fields, { add, remove }, { errors }) => (
                                            <Form.Item>
                                                {fields.map((field, index) => (
                                                    <Form.Item
                                                        required={false}
                                                        key={field.key}
                                                    >
                                                        <Form.Item
                                                            {...field}
                                                            //validateTrigger={['onChange', 'onBlur']}

                                                            noStyle
                                                        >
                                                            <Input placeholder="Descripción" style={{ width: '90%' }} />
                                                        </Form.Item>
                                                        {fields.length > 1 ? (
                                                            <AiOutlineMinusCircle
                                                                className="ml-1"
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        ) : null}
                                                    </Form.Item>

                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        style={{ width: '100%' }}

                                                    >
                                                        Agregar Descripción
                                                    </Button>


                                                </Form.Item>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>

                                        )
                                    }

                                </Form.List>
                            </Col>




                        </Row>
                        <Row>
                            <Form.Item >
                                <Button type="primary" htmlType="submit">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Row>

                    </Form>

                </Content>
            </>
        )
    }
}