import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography } from 'antd'

import axios from 'axios';
import { Link,useNavigate } from "react-router-dom";


import { DeleteOutlined, EditOutlined, InfoOutlined } from "@ant-design/icons";

// import { DeleteOutlined, EditOutlined, IconDetails } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalRoles from './ModalRoles'


import Logged from '../../../Hooks/Logged'
//import usePermissions from '../../../Hooks/usePermissions'; 

const { Content } = Layout;
const { Text, Title } = Typography;

/**
 * @export
 * @class Roles
 * @extends {Component}
 * @description Vista de Roles
 */
export class Roles extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,

            rol_id: undefined,
            roles: {
                data: [{}, {}, {}, {}],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
            searching: true,
            filtroSearch: '',

        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        // this.props.updateFilterSearch(true);
        this.getRoles()
    }


    /**
     *
     *
     * @memberof Roles
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    /* componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;
        if (filtro !== propFiltro && this.state.searching == true) {
            this.getRoles();
            this.state.searching = false;
        }
    } */

    /**
     * @methodOf  Roles
     * @method getRoles
     *
     * @description Obtiene las Roles
     * */
    getRoles = ({
        page = this.state.roles.page,
        limit = this.state.roles.limit,
        search = this.props.search
    } = this.state.roles) => {
        this.setState({ loading: true, roles: { ...this.state.roles, page, limit } })
        axios.get('/roles', {
            params: {
                page,
                limit, 
                search
            }
        }).then(({ data }) => {

            this.setState({
                roles: { ...this.state.roles, ...data },
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }

    render() {
        return (
            <Spin spinning={false}>
            <PageHeader 
                className="site-page-header custom-page-header"
                title={<Row>
                        <Col xs={20}>
                            <Title level={3}>Roles</Title>
                        </Col>
                    </Row>}
            />
            <Content className="admin-content content-bg pd-1">
                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin Roles " }}
                    dataSource={this.state.roles.data}
                    pagination={{
                        current: this.state.roles.page,
                        pageSize: this.state.roles.limit,
                        total: this.state.roles.total,
                        position: 'bottom',
                        className: "flex-left",
                        onChange: (page, limit) => this.getRoles({ page, limit })
                    }}
                    header={<Row className="component-list-header width-100 pr-1 pl-1">
                        <Col span={10} className="center">
                            <Text strong>Nombre</Text>
                        </Col>
                        <Col span={10} className="center">
                            <Text strong>Descripción</Text>
                        </Col>
                    </Row>}

                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list">
                                <Row className="width-100 " gutter={[8,8]}>

                                    <Col span={10} className="center">
                                        <Text>{item.nombre}</Text>
                                    </Col>
                                    <Col span={10} className="center">
                                        <Text>{item.descripcion}</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Space size="small" direction="horizontal">
                                            <Button
                                                type="primary"
                                                //disabled={!this.props.editRoles}
                                                icon={<EditOutlined />}
                                                title="Editar"
                                                onClick={() => this.setState({ modal_visible: true, rol_id: item._id })}
                                            />
                                            <Popconfirm
                                                placement="topRight"
                                                //disabled={!item.editable || !this.props.deleteRoles}
                                                title="¿Deseas eliminar este rol?"
                                                onConfirm={() => axios.delete('/roles',
                                                    { params: {rol_id: item._id} }).then(() => {
                                                        this.getRoles()
                                                        message.success('Rol eliminado')
                                                    }).catch(error => {
                                                        if(error.response.status === 403){
                                                            message.error(error.response.data.message)
                                                        }else{
                                                            message.error('Error al eliminar el rol')
                                                        }
                                                    })}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button
                                                    //disabled={!item.editable || !this.props.deleteRoles}
                                                    type="primary"
                                                    icon={<DeleteOutlined />}
                                                    title="Eliminar"
                                                    danger
                                                />
                                            </Popconfirm>
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />

            </Content>

           {/*  {this.props.createRoles ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} /> : null} */}

           <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} />

            <ModalRoles
                visible={this.state.modal_visible}
                onClose={() => {
                    this.setState({ modal_visible: false, rol_id: undefined })
                    this.getRoles()
                }}
                rol_id={this.state.rol_id}
            />
        </Spin>
        )
    }
}

export default function (props) {

    let user = React.useContext(Logged)
    
    /* let permissions = usePermissions(user?.rol_id?.permisos, {
        editRoles: ["roles", "edit"],
        createRoles: ["roles", "create"],
        deleteRoles: ["roles", "delete"]
    }) */
    
    console.log("Contexto Logged",user)
    //console.log("Contexto Logged",permissions)

    return <Roles {...props} navigate={useNavigate()}  />

}