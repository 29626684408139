import React, { Component } from 'react';

import { Layout, Row, Col, Menu, Typography, Button, Divider, Card, Input } from 'antd';

import '../../Styles/Public/footer.scss'
import { MailIcon, TelefonoIcon, UbicacionIcon } from '../Widgets/Iconos';
import { FaSquareFull } from 'react-icons/fa'
import { BsInstagram, BsFacebook, BsPinterest, BsTiktok } from 'react-icons/bs'
import { AiOutlineTwitter } from 'react-icons/ai'
import { Link } from 'react-router-dom';
import WidgetFacebook from './WidgetFacebook';
import { HashLink } from 'react-router-hash-link';

const { Header, Footer, Sider, Content } = Layout;

const { Text } = Typography;


class FooterPublic extends Component {
    render() {
        return (
            <>
                <Footer className='gradient-footer footer'>
                    <Row gutter={[0, 16]} align={"middle"} justify={"space-between"}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={8} xxl={4} className=' mr-1'>
                            <img className="w-100" src="/img/logo.svg" alt="José Hermosillo" style={{ maxWidth: '400px' }} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={17} xl={15} xxl={15} className="">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                    <Row align={"middle"}>
                                        <Col span={24} className='flex-left'>
                                            <TelefonoIcon />
                                            <a className="flex-column-left pl-1" href='https://api.whatsapp.com/send?phone=15312325998'>
                                                <Text className='info-titulo'>Alejandro Hurtado</Text>
                                                <Text className='info-datos'> +1 (531) 232-5998 </Text>
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                    <Row align={"middle"}>
                                        <Col span={24} className='flex-left'>
                                            <MailIcon />
                                            <a className="flex-column-left pl-1" href='mailto:fugapadurango2023@gmail.com'>
                                                <Text className='info-titulo'> Correo Electrónico </Text>
                                                <Text className='info-datos'>fugapadurango2023@gmail.com</Text>
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                    <Row align={"middle"}>
                                        <Col span={24} className='flex-left'>
                                            <UbicacionIcon />
                                            <a className="flex-column-left pl-1" href='https://goo.gl/maps/F8tRWazQwmpWDY858?coh=178571&entry=tt' target='_blank'>
                                                <Text className='info-titulo'>Ubicación</Text>
                                                <Text className='info-datos'>6052 L st Omaha Nebraska 68117</Text>
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider style={{ backgroundColor: "#6C6D72" }} />
                    <Row align={"middle"}>
                        <Col xs={24} sm={24} md={24} lg={14} xl={12} xxl={14}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                    <Row align="middle">
                                        <FaSquareFull color='#F26513' className='mr-1' />
                                        <Text className='titulo-footer'>
                                            Inicial
                                        </Text>
                                    </Row>
                                    <Row className='elementos-footer'>
                                        <ul>
                                            <li>
                                                <HashLink smooth to={'/#inicio'}>
                                                    Inicio
                                                </HashLink>
                                            </li>
                                            <li>
                                                <HashLink smooth to={'/#auto1'}>
                                                    Participar
                                                </HashLink>                                                {/* Nosotros */}
                                            </li>
                                            <li>
                                                <HashLink smooth to={'/#concursos'}>
                                                    Concursos
                                                </HashLink>
                                            </li>
                                            {/*<li>
                                                 Preguntas Frecuentes
                                            </li>
                                            <li>
                                                Ganadores
                                            </li> */}
                                        </ul>
                                    </Row>
                                </Col>
                                {/* <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                    <Row align="middle">
                                        <FaSquareFull color='#F26513' className='mr-1' />
                                        <Text className='titulo-footer'>
                                            Información
                                        </Text>
                                    </Row>
                                    <Row className='elementos-footer'>
                                        <ul>
                                            <li>Terminos y Condiciones</li>
                                            <li>Aviso de Privacidad</li>
                                        </ul>
                                    </Row>
                                </Col> */}
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={12} xxl={10}>
                            <WidgetFacebook />

                            {/* <Card className='card-suscribete'> */}

                            {/* <Row>
                                    <Text className='title'>Suscríbete</Text>
                                </Row>
                                <Row>
                                    <Text className='subtitle'>¡Recibe noticias de nuestros eventos!</Text>
                                </Row>
                                <Row gutter={[0, 16]}>
                                    <Col xs={24} sm={24} md={18} lg={24} xl={18} xxl={18}>
                                        <Input placeholder='Tu Correo Electrónico' />
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={24} xl={6} xxl={6}>
                                        <Button className='width-100'>Suscribete</Button>
                                    </Col>

                                </Row> */}
                            {/* </Card> */}
                        </Col>
                    </Row>

                    <Divider style={{ backgroundColor: "#6C6D72" }} />

                    <Row align={"middle"} justify={"space-between"}>
                        <Col >
                            <Text className='copyright'>Copyright © ISEEYOUTechnologies</Text>
                        </Col>
                        <Col>
                            <Text className='redes'>¡Síguenos en nuestras redes sociales!
                                <a href='https://www.instagram.com/sorteosfugapadurango/' target='_blank'><BsInstagram className='ml-1' /></a>
                                <a href='https://www.facebook.com/sorteosfugapadurango/' target='_blank'><BsFacebook className='ml-1' /></a>
                                <a href='https://www.tiktok.com/@fugapadgo/' target='_blank'><BsTiktok className='ml-1' /></a>
                            </Text>
                        </Col>
                    </Row>

                </Footer>
            </>
        );
    }
}

export default function (props) {

    return <FooterPublic />

}