import React from 'react'
import { Route, Routes } from "react-router-dom";
import Cuenta from '../../Components/Customer/Cuenta';

/**
 * 
 * @export
 * @function RouterCuenta
 * @description Router for Cuenta routes 
 */
function RouterCuenta(props) {
  return (
    <Routes>
      <Route path='/' element={<Cuenta />}/>
    </Routes>
  )
}

export default RouterCuenta