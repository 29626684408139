import React, { useContext } from 'react';
import { Space, Dropdown, Menu, Col, Row, Layout, Input, Typography} from 'antd';
import { useNavigate } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';
//componentes
import { User, SetUser } from '../../Hooks/Logged'

import '../../Styles/Global/header.scss'

const { Header } = Layout;
const { Search } = Input;
const { Text } = Typography;


/**
 * @const Navbar
 * @description Header del sistema
 * @param {*} {  showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal }
 */
const Navbar = ({ showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal, admin }) => {


	let user = useContext(User)
	let setUser = React.useContext(SetUser)
	const navigate = useNavigate()


	/**
	 * @const cerrarSesion
	 * @description Cierra la sesion
	 */
	const cerrarSesion = () => {
		setUser(undefined);
		sessionStorage.clear();
		navigate("/")

		if(window.FB){
        	window.FB.logout(function(response) {
        		console.log("response", response);
			});
        }

	};

	/**
	* @const UserMenu
	* @description Muestra el Menu del dashboard
	*/
	const UserMenu = () => {
		return (
			<Col flex="auto" className="header-content" style={{ display: "flex", flex: 1, columnGap: '1em', padding: "1rem 1rem"}} >
				<Search
					placeholder="Buscar"
					onSearch={(e) => { setFilterSearch(e) }}
					id="inputFilterSearch"
					enterButton
					size='large'
					className="input-global-search"
				/>
				<Space size={"large"}>
					
					<Dropdown
						className="dropdown-menu"
						arrow={true}
						overlay={
							<Menu>
								<Menu.Item key="0" onClick={cerrarSesion}>
									Cerrar Sesión
								</Menu.Item>
							</Menu>
						} trigger={['click']}>
						<Space>
							<Text>{user?.nombre} {user?.apellidos}</Text> <DownOutlined />
						</Space>
					</Dropdown>
				</Space>
			</Col>
		)
	}

	return (
		<Header className="header" >
			<Row className="row-header-content" >
				<Col className="header-logo" sm={24} xs={24}>
					<div className="logo center">
						<img src={'/img/logo.svg'} alt="logo"/>
					</div>
				</Col>
				{UserMenu()}
			</Row>
		</Header>
	);

}

export default Navbar;