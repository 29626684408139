import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import axios from "axios"

const { Option } = Select 

/**
 * @const SelectUsuario
 * @description Select para los usuarios registrados en el sistema
 */
const SelectUsuario = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione el usuario", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        mode = null
    } = props

    const [ usuarios, setUsuarios ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getUsuarios
     * @description Obitiene los usaurios
     */
    const getUsuarios = ({
        page, 
        limit, 
        search, 
    } = usuarios) => {

        axios.get('/usuarios', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setUsuarios(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los usuarios')
        })
    }

    //componentDidMount
    useEffect(() => {
        getUsuarios()
    }, [])


    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            mode={mode}
            onSearch={(search)=> getUsuarios({search})}
            onSelect={(usuario)=> {
                if(mode === null){
                    onChange(usuario.value)
                    onSelect(usuario.value)
                }
            }}
            onChange={(values)=>{
                if(mode === "multiple"){
                    let arr = values.map(e => e.value)
                    onChange(arr)
                    onSelect(arr)
                }
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {
                usuarios?.data?.map(({ _id, nombre, apellidos }) => <Option value={_id} key={_id}>{nombre ?? ""} {apellidos ?? ""}</Option>)
            }
        </Select>
    );
}



export default SelectUsuario;