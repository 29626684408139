import React from 'react';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined, EyeOutlined, EditOutlined, FileImageOutlined } from '@ant-design/icons';

const ButtonDelete = (props) => {

	let {
		type = "danger",
		className = "btn btn-delete",
		icon = <DeleteOutlined />,
		onConfirm = () => {},
		disabled=false
	} = props

	return <Popconfirm
            placement="topRight"
            title="¿Deseas eliminar este registro?"
            onConfirm={onConfirm}
            okText="Si"
            cancelText="No"
            disabled={disabled}
        >
        	<Button
				{...props}
				type={type}
				className={className}
				icon={icon}
				disabled={disabled}
				//onClick={onClick}
			/>
		</Popconfirm>
}

const ButtonView = (props) => {

	let {
		type = "primary",
		className = "btn btn-delete",
		icon = <EyeOutlined />,
		onClick = () => {},
	} = props

	return <Button
		{...props}
		type={type}
		className={className}
		icon={icon}
		onClick={onClick}
	/>
}

const ButtonEdit = (props) => {

	let {
		type = "primary",
		className = "btn btn-delete",
		icon = <EditOutlined />,
		onClick = () => {},
	} = props

	return <Button
		{...props}
		type={type}
		className={className}
		icon={icon}
		onClick={onClick}
	/>
}

const ButtonImg = (props) => {

	let {
		type = "primary",
		className = "btn btn-delete",
		icon = <FileImageOutlined />,
		onClick = () => {},
	} = props

	return <Button
		{...props}
		type={type}
		className={className}
		icon={icon}
		onClick={onClick}
	/>
}


export {
	ButtonDelete,
	ButtonView,
	ButtonEdit,
	ButtonImg
}