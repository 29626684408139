import React from 'react'
import { Route, Routes } from "react-router-dom";
import { useEffect } from 'react';
import Roles from '../Components/Admin/Roles/Roles'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterAreas(props) {

    

    return (
        <Routes>
            <Route path="" element={<Roles {...props} />} />
        </Routes>
    )
}

export default RouterAreas;
