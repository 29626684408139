import React, { useEffect } from 'react'
import { Route, Routes } from "react-router-dom";
import { Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
//componentes
import ThemeEdit from '../Components/ThemeEdit/index';
import Landing from '../Components/Public/Landing';
import Info from '../Components/Public/Info';
import Login from '../Components/Auth/Login';
import Recovery from '../Components/Auth/Recovery';
import UpdatePassword from '../Components/Auth/UpdatePassword';
import Register from '../Components/Auth/Register';
import { SetCarrito, Carrito } from '../Hooks/Carrito';
import { SetGuest, Guest } from '../Hooks/Guest';
import { Row, Col, Avatar } from 'antd';

/**
 * @function PublicRoutes
 * @description Router para rutas publicas
 * @export
 */
function PublicRoutes({ setUser }) {

	let [carrito, setCarrito] = React.useState([]);
	let [guest, setGuest] = React.useState(null);

	const navigate = useNavigate();

	return (
		<Guest.Provider value={guest}>
			<SetGuest.Provider value={setGuest}>
				<Carrito.Provider value={carrito}>
					<SetCarrito.Provider value={setCarrito}>
						<Layout>
							<Routes>
								<Route path="" element={<Landing />} />
								<Route path="evento/:unique_url" element={<Info />} />
								<Route path='login' element={<Login />} />
								<Route path='password/recovery' element={<Recovery />} />
								<Route path='recovery/:token' element={<UpdatePassword />} />
								<Route path='register/:token' element={<UpdatePassword />} />
								<Route path='register' element={<Register />} />
								<Route
									path="theme"
									element={<ThemeEdit />}
								/>
							</Routes>
							<Row align={"middle"} justify={"end"} gutter={[20, 0]} className="sticky-redes mr-1 mb-1">
								<Col>
									<a href='https://api.whatsapp.com/send?phone=15312325998' target="_blank" rel="noreferrer">
										<Avatar size={65} src="/img/whatsapp_logo.svg" className="whatss" style={{ backgroundColor: "#FFFFFF", border: "12px solid white", boxShadow: "4px 4px 15px rgba(48, 48, 48, 0.10)" } } />
									</a>
								</Col>
							</Row>
							<Button className='oculto' onClick={() => navigate("/login")} > Probando </Button>
						</Layout>
					</SetCarrito.Provider>
				</Carrito.Provider>
			</SetGuest.Provider>
		</Guest.Provider>


	)
}

export default PublicRoutes;