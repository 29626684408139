import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select } from 'antd';
import axios from "axios"

//componentes
import PhoneInput from "../../Widgets/Inputs/PhoneInput";
import { SelectUsuarios } from "../../Widgets/Inputs/Selects";

const { Option } = Select;


/**
 * @class ModalUsuarios
 * @description Modal para el CRUD de Usuarios
 */
class ModalUsuarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            roles: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.cliente_id)
            this.getCliente()

    }

    formRef = React.createRef();

    /**
    * @method getCliente
    * @description Obtiene la informacion de un cliente
    */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/cliente/' + this.props.cliente_id)
        .then(({data}) => {
            this.formRef.current.setFieldsValue({
                ...data,
                vendedor_id: data?.vendedor_id?._id ?{
                    value: data?.vendedor_id?._id,
                    label: `${data?.vendedor_id?.nombre} ${data.vendedor_id.apellidos}`,
                } : null,
            })
        }).catch(error => {
            message.error('Error al obtener el cliente')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {

        if(values.vendedor_id?.value) values.vendedor_id = values.vendedor_id.value

        if (this.props.cliente_id) {
            this.updateCliente(values)
        } else {
            this.addCliente(values)
        }
    }



    /**
    * @method addCliente
    * @description Añade un nuevo registro de usuario
    */
    addCliente = (values) => {
        this.setState({ loading: true })
        axios.post('/cliente', {
            ...values
        }).then(response => {
            message.success('¡Cliente Creado!')
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error);
            message.error(error.response?.data?.message ?? 'Error al crear el cliente')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateCliente
    * @description Añade un nuevo registro de usuario
    */
    updateCliente = (values) => {
        this.setState({ loading: true })
        axios.put('/cliente/'+this.props.cliente_id, {
            ...values,
        }).then(response => {
            message.success('¡Cliente Actualizado!')
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error);
            message.error(error.response?.data?.message ?? 'Error al actualizar el Cliente')
        }).finally(() => this.setState({ loading: false }))
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-cliente"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center" gutter={[16,0]}>

                        <Col xs={24} md={20}>

                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el nombre',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={10}>

                            <Form.Item
                                name="apellido_paterno"
                                label="Apellido Paterno"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el apellido paterno',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={10}>

                            <Form.Item
                                name="apellido_materno"
                                label="Apellido Materno"
                            >
                                <Input />
                            </Form.Item>

                        </Col>
                        <Col xs={24} md={20}>
                            <Form.Item
                                name="telefono"
                                label="Teléfono"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el Teléfono',
                                    },
                                ]}
                            >
                                <PhoneInput />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label="E-mail"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email no valido',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="ciudad"
                                label="Ciudad"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="codigo_postal"
                                label="Código postal"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="estatus"
                                label="Estatus"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el estatus del cliente',
                                    },
                                ]}
                            >
                                <Select>
                                    <Option value={0}>Desitió / Perdido</Option>
                                    <Option value={1}>Registrado</Option>
                                    <Option value={2}>Contactado</Option>
                                    <Option value={3}>Ha Comprado</Option>
                                    <Option value={4}>Pagado</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="vendedor_id"
                                label="Vendedor"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el vendedor',
                                    },
                                ]}
                            >
                                <SelectUsuarios
                                    params={{
                                        vendedores: true
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, cliente_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={cliente_id ? "Editar Cliente" : "Crear Cliente"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-cliente', key: 'submit', htmlType: 'submit' }}
    >
        <ModalUsuarios  {...props} />
    </Modal>

}