import React, { Component, useContext } from "react";
import { Row, Col, Modal, Form, Input, Spin, Alert } from 'antd';

//componentes
import PhoneInput from "../Widgets/Inputs/PhoneInput";
import { SetGuest } from "../../Hooks/Guest";



/**
 * @class ModalGuest
 * @description Modal para agregar datos importantes del invitado
 */
class ModalGuest extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            roles: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
        }
    }

    formRef = React.createRef();

    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {
        console.log("Setting GUest: ", values)
        this.props.setGuest(values)
        this.props.onSuccess()
    }

    4

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-cliente"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center" gutter={[16, 0]}>
                        <Col xs={24} style={{ marginBottom: 14 }}>
                            <Alert
                                message="¡Dejanos conocerte!"
                                description="¡Antes de elegir tus boletos, queremos hacerte la experiencia de compra mucho más sencilla y rápida! ¿podrías compartir con nosotros algunos datos? De esta manera, podremos agilizar el proceso para ti. ¡Gracias por tu colaboración!"
                                type="info"
                                showIcon
                            />
                        </Col>
                        <Col xs={24}>

                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el nombre',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>

                            <Form.Item
                                name="apellido_paterno"
                                label="Apellido Paterno"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el apellido paterno',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                name="telefono"
                                label="Teléfono"
                                rules={
                                    [
                                        {
                                            required: true,
                                            message: 'Ingrese el Teléfono',
                                        },
                                        {
                                            pattern: new RegExp(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/),
                                            message: 'El teléfono no es valido',
                                        }
                                    ]
                                }
                            >
                                <PhoneInput />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, cliente_id } = props
    const setGuest = useContext(SetGuest)

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={"Datos del Invitado"}
        closable={true}
        destroyOnClose={true}
        zIndex={1300}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-cliente', key: 'submit', htmlType: 'submit' }}
        getContainer={() => document.getElementById("root")}
    >
        <ModalGuest  {...props} setGuest={setGuest} />
    </Modal>

}