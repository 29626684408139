import React, { Component } from "react";
import { Layout, Menu, Button } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import '../../Styles/Global/sider.css';
import { useNavigate } from "react-router-dom"
import { FaBuilding, FaUsersCog } from "react-icons/fa"
import { AiOutlineUser } from "react-icons/ai"
import { AppstoreFilled, UserOutlined, DollarOutlined, TeamOutlined, StarOutlined, FileImageOutlined } from '@ant-design/icons';
const { SubMenu } = Menu;
const { Sider } = Layout;

/**
 *
 *
 * @export
 * @class Admin_Sidebar
 * @extends {Component}
 */
class AdminSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false
        }
    }


    /**
     *
     *
     * @memberof AdminSidebar
     */
    toogle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    render() {
        return (
            <Sider
                theme="light"
                width={250}
                collapsedWidth={0}
                breakpoint="lg"

                className="custom-sider sider-width"
            //collapsed={this.state.collapsed}
            //collapsible
            >

                <Menu
                    mode="inline"
                    theme="light"
                    className="heigth-100 "
                    style={{ borderRight: 0 }}
                >
                    <Button ghost onClick={this.toogle} className="no-border">
                        {this.state.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>


                    <Menu.ItemGroup key="navegacion" title="NAVEGACIÓN">

                        <Menu.Item key="dashboard" icon={<AppstoreFilled />} onClick={() => this.props.navigate("/admin/dashboard", { replace: true })}>
                            Dashboard
                        </Menu.Item>
                        <Menu.Item key="ventas" icon={<DollarOutlined />} onClick={() => this.props.navigate("/admin/ventas", { replace: true })}>
                            Ventas
                        </Menu.Item>

                    </Menu.ItemGroup>
                    <Menu.Divider className="group-sider-divider " />
                    <Menu.ItemGroup key="g2" title="AJUSTES">
                        <Menu.Item key="usuarios" icon={<UserOutlined />} onClick={() => this.props.navigate("/admin/usuarios", { replace: true })}>
                            Usuarios
                        </Menu.Item>
                        <Menu.Item key="roles" icon={<FaUsersCog />} onClick={() => this.props.navigate("/admin/roles", { replace: true })}>
                            Roles
                        </Menu.Item>
                        <Menu.Item key="rifas" icon={<StarOutlined />} onClick={() => this.props.navigate("/admin/rifas", { replace: true })}>
                            Sorteos
                        </Menu.Item>
                        <Menu.Item key="clientes" icon={<TeamOutlined />} onClick={() => this.props.navigate("/admin/clientes", { replace: true })}>
                            Clientes
                        </Menu.Item>
                        <Menu.Item key="carousel" icon={<FileImageOutlined />} onClick={() => this.props.navigate("/admin/carousel", { replace: true })}>
                            Carousel
                        </Menu.Item>

                    </Menu.ItemGroup>
                </Menu>

            </Sider>
        )
    }
}

export default function View(props) {
    return <AdminSidebar navigate={useNavigate()} {...props} />
}
